import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// icons and logos
import AppleLogo from "../../assets/logos/AppleLogo.svg";
import GoogleLogo from "../../assets/logos/PlayMarketLogo.svg";
import logo from "../../assets/logos/mobile-logo.svg";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function GetMobileApp() {
  const theme = useTheme();
  const textSmall = useMediaQuery(theme.breakpoints.down("md"));
  const textMedium = useMediaQuery(theme.breakpoints.down("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const paddingDimensions = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: "#D4D4D4",
        width: "100%",
        my: 30,
        position: "relative",
        mx: paddingDimensions ? 0 : 20,
      }}
    >
      {!textSmall ? (
        <div
          style={{
            position: "absolute",
            bottom: "-60%",
            left: "-5%",
          }}
        >
          <img
            alt={"mobile-logo"}
            width={textMedium ? "80%" : isTablet ? "90%" : "100%"}
            src={logo}
          />
        </div>
      ) : (
        <></>
      )}

      <Grid
        container
        direction="row"
        justifyContent={textSmall ? "center" : "flex-end"}
      >
        <Grid item sx={{ my: 3, mx: textSmall ? 4 : 10 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 800,
                  fontSize: textSmall ? 28 : textMedium ? 48 : 64,
                  color: "#3F3F46",
                }}
              >
                It's even easier
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: 800, fontSize: 18, color: "#3F3F46" }}
              >
                Get our APP right now
              </Typography>
            </Grid>
            <Grid item sx={{ my: 4 }}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    sx={{
                      color: "white",
                      px: 2,
                      textTransform: "none",
                      background: "#3F3F46",
                      ":hover": {
                        background: "#3F3F46",
                        opacity: 0.7,
                      },
                    }}
                    startIcon={<img src={AppleLogo} alt="apple" />}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography sx={{ fontWeight: 800, fontSize: 10 }}>
                          Available on the
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>
                          App Store
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: "white",
                      px: 2,
                      textTransform: "none",
                      background: "#3F3F46",
                      ":hover": {
                        background: "#3F3F46",
                        opacity: 0.7,
                      },
                    }}
                    startIcon={<img src={GoogleLogo} alt="google" />}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography sx={{ fontWeight: 500, fontSize: 10 }}>
                          GET IT ON
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>
                          Google Play
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
