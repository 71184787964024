import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import SimpleAuthNavigationBar from "../components/auth/nav-bar";
import AdminAuth from "../components/admin-views/auth";

export default function AdminLogin() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", flexGrow: 1, height: "100vh" }}>
      <CssBaseline />
      <SimpleAuthNavigationBar />
      <AdminAuth />
    </Box>
  );
}
