import * as React from "react";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// components
import Button from "@mui/material/Button";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// constants
import { API_URL } from "../../constants";

// icons and logos
import google from "../../assets/logos/google.svg";

// google oauth client id
const clientID =
  "431277061803-61ndd36nav2mschaq6ngqfr075h1itji.apps.googleusercontent.com";

export default function GoogleSignInUpButton(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let navigate = useNavigate();

  const responseGoogle = (response) => {
    console.log(response.profileObj);

    var profileObject = response.profileObj;

    if (props.exists) {
      // axios call & body
      const body = {
        email: profileObject.email,
      };

      console.log(body);

      if (props.business) {
        axios.post(`${API_URL}/businesssigninwithgoogle`, body).then((res) => {
          console.log(res);
          localStorage.setItem("business", JSON.stringify(res.data));
          navigate("/business-dashboard");
        });
      } else {
        axios.post(`${API_URL}/signinwithgoogle`, body).then((res) => {
          console.log(res);
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/search-jobs");
        });
      }
    } else {
      // axios call & body
      const body = {
        name: profileObject.givenName,
        lastname: profileObject.familyName,
        email: profileObject.email,
      };

      console.log(body);

      if (props.business) {
        axios.post(`${API_URL}/businesssignupwithgoogle`, body).then((res) => {
          console.log(res);
          localStorage.setItem("business", JSON.stringify(res.data));
          navigate("/business-dashboard");
        });
      } else {
        axios.post(`${API_URL}/signupwithgoogle`, body).then((res) => {
          console.log(res);
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/search-jobs");
        });
      }
    }
  };

  const error = (response) => {
    console.log(response);
  };

  return (
    <GoogleLogin
      clientId={clientID}
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          variant="tertiary"
          startIcon={
            <img src={google} alt="google" style={{ marginRight: 5 }} />
          }
          sx={{
            width: "100%",
            my: 1.5,
            justifyContent: "flex-start",
            px: isSmall ? 7 : 8.5,
          }}
        >
          {isSmall ? "Sign In" : "Sign In with Google"}
        </Button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={error}
      cookiePolicy={"single_host_origin"}
    />
  );
}
