import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// custom components
import AdminSignIn from "./components/sign-in";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function AdminAuth() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="row" justifyContent="center">
      <Paper
        variant="outlined"
        square
        sx={{
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
          marginTop: 10,
          marginBottom: 4,
          px: 2,
          width: isMobile ? "100%" : 450,
        }}
      >
        <AdminSignIn />
      </Paper>
    </Grid>
  );
}
