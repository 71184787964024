import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// custom components
import BusinessSignIn from "./components/sign-in";
import BusinessSignUp from "./components/sign-up";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function BusinessAuth(props) {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="row" justifyContent="center">
      <Paper
        variant="outlined"
        square
        sx={{
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
          marginTop: 10,
          marginBottom: 4,
          px: 2,
          width: isMobile ? "100%" : 450,
        }}
      >
        <Box sx={{ px: 4, py: 2 }}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Typography
                sx={{ color: "#3F3F46", fontWeight: 700, fontSize: 18 }}
              >
                Business Dashboard
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{ marginTop: 0.5, marginBottom: 2 }}
          >
            <Grid item>
              <Typography
                sx={{ color: "#A3A3A3", fontWeight: 500, fontSize: 14 }}
              >
                Boost your team with ISAP Jobs.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={6}>
              <Button
                href="/sign-in/business"
                variant="primary"
                color={props.signUp ? "secondary" : "primary"}
                sx={{
                  py: 1.5,
                  width: "100%",
                  fontWeight: "bold",
                  ":hover": {
                    opacity: props.signUp ? 0.7 : 1,
                  },
                }}
              >
                Sign In
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                href="/sign-up/business"
                variant="primary"
                color={!props.signUp ? "secondary" : "primary"}
                sx={{
                  py: 1.5,
                  width: "100%",
                  fontWeight: "bold",
                  ":hover": {
                    opacity: !props.signUp ? 0.7 : 1,
                  },
                }}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
          {props.signUp ? <BusinessSignUp /> : <BusinessSignIn />}
        </Box>
      </Paper>
    </Grid>
  );
}
