import * as React from "react";
// components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// icons and logo
import search from "../../assets/icons/ab-search-white.svg";
import projects from "../../assets/icons/ab-projects-white.svg";

export default function BusinessHome(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      sx={{ paddingTop: 15 }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: 64,
                fontWeight: "bold",
                color: "#3F3F46",
              }}
            >
              Welcome
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: 1 }}>
            <Typography
              sx={{ fontSize: 18, fontWeight: 500, color: "#3F3F46" }}
            >
              to iSAP Jobs
            </Typography>
          </Grid>
          <Grid item sx={{ my: 4 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: "#3F3F46",
                textAlign: "center",
              }}
            >
              The world's leading platfrom for outsourcing and hiring IT
              specialists!
            </Typography>
          </Grid>
          <Grid item width="100%">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={4}
            >
              <Grid item xs={6}>
                <Button
                  href="/business-dashboard/search-cv"
                  variant="secondary"
                  sx={{ width: "100%", py: 1.25, fontWeight: 700 }}
                  startIcon={<img src={search} alt="search" />}
                >
                  Search CV
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  href="/business-dashboard/projects"
                  variant="primary"
                  sx={{ width: "100%", py: 1.25, fontWeight: 700 }}
                  startIcon={<img src={projects} alt="projects" />}
                >
                  Create project
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
