import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

// custom components
import SignUpForm from "./signup-form";
import SignInForm from "./signin-form";
import AutheticationPage from "../../pages/sign-up-page";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Main(props) {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // modal settings
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Grid container direction="row" justifyContent="center">
      <Paper
        variant="outlined"
        square
        sx={{
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
          marginTop: props.homePage ? 4 : 10,
          marginBottom: 4,
          px: 2,
          width: isMobile ? "100%" : 450,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ paddingTop: 2 }}
        >
          <Grid item xs={6}>
            <Button
              href="/sign-in"
              variant="primary"
              color={props.signUp ? "secondary" : "primary"}
              sx={{
                py: 1.5,
                width: "100%",
                fontWeight: "bold",
                ":hover": {
                  opacity: props.signUp ? 0.7 : 1,
                },
              }}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              href="/sign-up"
              variant="primary"
              color={!props.signUp ? "secondary" : "primary"}
              sx={{
                py: 1.5,
                width: "100%",
                fontWeight: "bold",
                ":hover": {
                  opacity: !props.signUp ? 0.7 : 1,
                },
              }}
            >
              Sign Up
            </Button>
            <Modal open={open} onClose={handleClose}>
              <AutheticationPage />
            </Modal>
          </Grid>
        </Grid>

        {props.signUp ? <SignUpForm /> : <SignInForm />}
      </Paper>
    </Grid>
  );
}
