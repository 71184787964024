import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// icons and logos
import SearchIcon from "@mui/icons-material/Search";

// custom components
import JobAd from "../../components/search/job-ad";
import PaginationC from "../search/pagination";
import AdminProjectsFilter from "./components/projects-filter";

// constants
import { API_URL } from "../../constants";

export default function AdminSearchProjects() {
  // pagination variables
  const PER_PAGE = 10;

  // rest api variables
  const [total, setTotal] = React.useState(0);
  const [projects, setProjects] = React.useState([]);

  const [searchKeyword, setSearchKeyword] = React.useState("");

  React.useEffect(() => {
    // get all jobs
    axios.get(`${API_URL}/getAllJobs`).then((res) => {
      setProjects(res.data);
      setTotal(res.data.length);
    });
  }, []);

  const changePage = (page, count) => {
    // get first page jobs
    const body = {
      page: page,
      count: count,
    };

    console.log(body);
    window.scrollTo(0, 0);
  };

  const searchFor = (keyword) => {
    // axios call & body
    const body = {
      find: keyword,
    };

    console.log(body);
  };

  const count = Math.ceil(total / PER_PAGE);

  const handleChange = (event, value) => {
    changePage(value, PER_PAGE);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        paddingLeft: 10,
        paddingRight: 30,
        py: 5,
        height: total > 5 ? "100%" : "100vh",
      }}
    >
      {total.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: "50%",
            left: "55%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              color: "#3F3F46",
              fontWeight: 700,
              fontSize: 18,
              marginTop: 2,
            }}
          >
            {total} Projects Found
          </Typography>
          <Typography
            sx={{
              color: "#A3A3A3",
              fontWeight: 500,
              fontSize: 14,
              marginBottom: 2,
            }}
          >
            Showing {PER_PAGE} projects per page
          </Typography>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Paper elevation={0} sx={{ px: 2, py: 1, borderRadius: 0 }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          variant="standard"
                          onChange={(event) =>
                            setSearchKeyword(event.target.value)
                          }
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => searchFor(searchKeyword)}
                          variant="primary"
                        >
                          <SearchIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <AdminProjectsFilter />
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={4}>
                    {projects.map((job, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        key={index}
                      >
                        <JobAd
                          jobname={job.jobname}
                          description={job.description}
                          time={job.time}
                          location={job.location}
                          admin={true}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item>
                  <PaginationC count={count} handleChange={handleChange} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
