import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat"',
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  components: {
    MuiStep: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "#1D4ED8", // circle color (COMPLETED)
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "#3F3F46", // Just text label (COMPLETED)
            fontWeight: 700,
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "#3F3F46", // circle color (ACTIVE)
            fontWeight: 700,
          },
          "& .MuiStepLabel-label .Mui-active .MuiStepLabel-alternativeLabel": {
            color: "#3F3F46", // Just text label (ACTIVE)
          },
          "& .Mui-disabled .MuiStepIcon-root": {
            color: "#E5E5E5", // circle color (DISABLED)
          },
          "& .Mui-disabled .MuiStepLabel-label": {
            fontWeight: 700,
            color: "#3F3F46", // Just text label (DISABLED)
            opacity: 0.6,
          },
          "& .MuiStepLabel-root .MuiStepIcon-text": {
            fill: "#3F3F46", // circle's number (DISABLED)
            fontWeight: 700,
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "white", // circle's number (ACTIVE)
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "red",
          },
          "&.Mui-active": {
            color: "#3F3F46",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#3F3F46",
          color: "white",
          fontSize: 14,
          fontWeight: 500,
          borderRadius: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#3F3F46",
          "&.Mui-checked": {
            color: "#3F3F46",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#A3A3A3",
          "&.Mui-focused": {
            color: "#1D4ED8",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#A3A3A3",
          },
          "& label.Mui-focused": {
            color: "#1D4ED8",
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "#A3A3A3",
          },
          "& .MuiInput-underline:hover:before": {
            borderBottomColor: "#A3A3A3",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#1D4ED8",
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          style: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
        inputProps: {
          style: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "primary", color: "primary" },
          style: {
            backgroundColor: "#1D4ED8",
            color: "#FFFFFF",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#1D4ED8",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "primary", color: "secondary" },
          style: {
            backgroundColor: "#E5E5E5",
            color: "#3F3F46",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#E5E5E5",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "primary", color: "tertiary" },
          style: {
            backgroundColor: "#D4D4D4",
            color: "#3F3F46",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#D4D4D4",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            backgroundColor: "#3F3F46",
            color: "#FFFFFF",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#3F3F46",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "secondary", color: "reverse" },
          style: {
            backgroundColor: "#FFFFFF",
            color: "#3F3F46",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#FFFFFF",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "tertiary" },
          style: {
            backgroundColor: "#A3A3A3",
            color: "#FFFFFF",
            textTransform: "none",
            fontSize: 16,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "#A3A3A3",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "transparent", color: "primary" },
          style: {
            backgroundColor: "transparent",
            color: "#3F3F46",
            textTransform: "none",
            fontSize: 13,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "transparent",
              opacity: 0.7,
            },
          },
        },
        {
          props: { variant: "transparent", color: "secondary" },
          style: {
            backgroundColor: "transparent",
            color: "#A3A3A3",
            textTransform: "none",
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 0,
            px: 2,
            py: 1,
            ":hover": {
              backgroundColor: "transparent",
              opacity: 0.7,
            },
          },
        },
      ],
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
