import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import Footer from "../components/home/footer";
import NavigationBar from "../components/search/nav-bar";
import Settings from "../components/user-settings/settings";

export default function AccountSettings() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", height: "100vh", flexGrow: 1 }}>
      <CssBaseline />
      <NavigationBar account={true} />
      <Settings />
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <Footer />
      </div>
    </Box>
  );
}
