import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// custom components
import NameDate from "./name-date";

export default function Employment(props) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <NameDate name={props.name} dates={props.dates} />
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}>
          {props.descriptions.map((description, index) => (
            <li key={index}>{description}</li>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
}
