import * as React from "react";
import { useNavigate } from "react-router-dom";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// icons and logos
import forgot from "../../assets/icons/forgot-done.svg";

export default function Recovery() {
  let navigate = useNavigate();
  const [isSent, setIsSent] = React.useState(false);

  const sendEmail = () => {
    setIsSent(true);
  };

  const backHome = () => {
    navigate("/");
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Paper
        variant="outlined"
        square
        sx={{
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
          width: 430,

          py: 2,
          marginTop: 10,
          marginBottom: 4,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 18,
                marginTop: 1,
                marginBottom: 2,
              }}
            >
              Recover Password
            </Typography>
          </Grid>

          {isSent ? (
            <>
              <img alt={"forgot-done"} src={forgot} />
              <Typography sx={{ fontSize: 14, fontWeight: 500, marginTop: 2 }}>
                Check your Email
              </Typography>
            </>
          ) : (
            <TextField
              sx={{ width: 310 }}
              id="email-recover"
              label="Email"
              variant="standard"
            />
          )}

          <Grid item>
            {isSent ? (
              <Button
                onClick={backHome}
                variant="tertiary"
                sx={{ my: 3, px: 7, fontSize: 16 }}
              >
                Go to Home Page
              </Button>
            ) : (
              <Button
                onClick={sendEmail}
                variant="primary"
                sx={{ my: 3, px: 7, fontSize: 16 }}
              >
                Send
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
