import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// custom components
import JobAd from "../search/job-ad";
import GetMobileApp from "./mobile-app";
import Footer from "./footer";
import HowTo from "./how-to";
import JobsByShort from "./jobs-by";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// constants
import { API_URL } from "../../constants";

export default function LatestJobs() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const textSmall = useMediaQuery(theme.breakpoints.down("md"));
  const textMedium = useMediaQuery(theme.breakpoints.down("lg"));
  const paddingDimensions = useMediaQuery(theme.breakpoints.down("lg"));

  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }
  }, []);

  // rest api variables
  const [latestJobs, setLatestJobs] = React.useState([]);

  React.useEffect(() => {
    // get all jobs
    axios.get(`${API_URL}/getAllJobs`).then((res) => {
      setLatestJobs(res.data);
    });
  }, []);

  return (
    <Box sx={{ backgroundColor: "#F5F5F5", position: "relative" }}>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        sx={{ px: paddingDimensions ? 4 : 20 }}
      >
        <Grid item>
          <Box
            sx={{
              background: "#FFFFFF",
              position: "relative",
              top: -15,
            }}
          >
            <JobsByShort />
          </Box>
        </Grid>
        <Grid item sx={{ paddingTop: 10 }}>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: textSmall ? 28 : textMedium ? 48 : 64,
              color: "#3F3F46",
            }}
          >
            Latest Jobs
          </Typography>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: textSmall ? 28 : textMedium ? 48 : 64,
              color: "#3F3F46",
            }}
          >
            & Projects Exchange
          </Typography>
        </Grid>
        <Box sx={{ position: "relative" }}>
          <Grid item sx={{ my: 3 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 4 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
            >
              {latestJobs.slice(0, 6).map((job, index) => (
                <Grid item xs={4} sm={8} md={4} lg={4} xl={4} key={index}>
                  <JobAd
                    jobname={job.jobname}
                    description={job.description}
                    time={job.time}
                    location={job.location}
                    admin={false}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Box
            sx={{
              bottom: 0,
              position: "absolute",
              width: "100%",
              height: "250px",
              background:
                "linear-gradient(360deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%)",
            }}
          />
          <Box sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Button
                  href={user ? "/search-jobs" : "/sign-up"}
                  variant="primary"
                  sx={{
                    px: 13,
                    py: 1.75,
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  Find More
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid container direction="row" justifyContent="center">
        <GetMobileApp />
      </Grid>

      <HowTo />
      <Footer />
    </Box>
  );
}
