import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default function Skill() {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <TextField
          required
          fullWidth
          id="group-skill"
          label="Skills Group name"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <TextField
          required
          fullWidth
          id="skills-skill"
          label="Skills"
          variant="standard"
        />
      </Grid>
    </Grid>
  );
}
