import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// components
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

// custom components
import SocialMediaAuth from "../../auth/social-media-signIn";

// constants
import { API_URL } from "../../../constants";

export default function BusinessSignUp(props) {
  let navigate = useNavigate();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [type, setType] = React.useState("");

  const selectType = (event) => {
    setType(event.target.value);
  };

  const signUpManually = () => {
    // axios call & body
    const body = {
      type: type,
      name: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      pass: password,
      confirmpass: confirmPassword,
      companyname: companyName,
      position: position,
    };

    console.log(body);

    axios.post(`${API_URL}/businesssignup`, body).then((res) => {
      console.log(res);
      navigate("/business-dashboard");
    });
  };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      <Grid item>
        <FormControl variant="standard" fullWidth required>
          <InputLabel
            id="demo-simple-select-standard-label"
            sx={{
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            Your Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={type}
            onChange={selectType}
            label="Your Type"
            sx={{
              ":before": { borderBottomColor: "#A3A3A3" },
              ":after": { borderBottomColor: "#1D4ED8" },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#E5E5E5",
                  borderRadius: 0,
                },
                elevation: 0,
              },
            }}
          >
            <MenuItem value={0}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                Employer
              </Typography>
            </MenuItem>
            <MenuItem value={1}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                Human Resources
              </Typography>
            </MenuItem>
            <MenuItem value={2}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                Project Manager
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setFirstName(event.target.value)}
          required
          fullWidth
          id="firstname-required"
          label="First Name"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setLastName(event.target.value)}
          required
          fullWidth
          id="lastname-required"
          label="Last Name"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setEmail(event.target.value)}
          required
          fullWidth
          id="email-required"
          label="Email"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setCompanyName(event.target.value)}
          required
          fullWidth
          id="company-required"
          label="Company name"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setPosition(event.target.value)}
          required
          fullWidth
          id="position-required"
          label="Position"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setPhone(event.target.value)}
          fullWidth
          id="phonenumber"
          label="Phone Number"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setPassword(event.target.value)}
          required
          fullWidth
          id="password-required"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setConfirmPassword(event.target.value)}
          required
          fullWidth
          id="confirm-password-required"
          label="Confirm Password"
          type="password"
          variant="standard"
        />
      </Grid>

      <Grid item>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Button
              onClick={signUpManually}
              variant="primary"
              disableElevation
              sx={{
                my: 3,
                px: 5,
                fontSize: 16,
              }}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Divider spacing={1} sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "normal", fontSize: 15, color: "gray" }}>
          OR
        </Typography>
      </Divider>

      <SocialMediaAuth business={true} />
    </Grid>
  );
}
