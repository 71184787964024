import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import Footer from "../components/home/footer";
import RegisterCVMain from "../components/cv-registration/main";
import NavigationBar from "../components/search/nav-bar";

export default function RegisterCVPage() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", flexGrow: 1 }}>
      <CssBaseline />
      <NavigationBar account={true} />
      <RegisterCVMain />
      <Footer />
    </Box>
  );
}
