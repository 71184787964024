import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// icons and logos
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import ExpandIcon from "@mui/icons-material/ExpandMore";

// custom components
import ProjectCard from "./components/project-card";
import ProjectDetailedFilter from "./components/project-detailed-filter";
import CandidateCard from "./components/candidate-card";

// constants
import { API_URL } from "../../constants";

export default function ProjectDetailed() {
  // rest api variables
  const [allCV, setAllCV] = React.useState([]);

  React.useEffect(() => {
    // get all jobs
    axios.get(`${API_URL}/getallcv`).then((res) => {
      setAllCV(res.data);
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#F5F5F5", p: 3 }}>
      <Button
        href="/business-dashboard/projects"
        disableElevation
        startIcon={
          <BackIcon sx={{ fontWeight: "700", fontSize: 16, lineHeight: 20 }} />
        }
        sx={{
          color: "#3F3F46",
          fontWeight: "bold",
          textTransform: "none",
          fontSize: 14,
        }}
      >
        Back to projects
      </Button>

      <Grid container direction="column" sx={{ my: 1 }} spacing={2}>
        <Grid item>
          <ProjectCard isDetailed={false} />
        </Grid>

        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Typography
                sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              >
                Full Description
              </Typography>
            </Grid>
            <Grid item>
              <ExpandIcon sx={{ color: "#3F3F46", fontSize: 21 }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <ProjectDetailedFilter />
        </Grid>

        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Typography
                sx={{ color: "#3F3F46", fontSize: 14, fontWeight: 500 }}
              >
                Sort by date
              </Typography>
            </Grid>
            <Grid item>
              <ExpandIcon sx={{ color: "#3F3F46", fontSize: 21 }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ mx: 2 }}>
          <Grid item xs={8}>
            {Array.from(Array(6)).map((_, index) => (
              <Grid item key={index}>
                <CandidateCard info={allCV[index]} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
