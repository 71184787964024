import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

export default function Settings() {
  const [checked, setChecked] = React.useState(true);
  const agreeToEmails = (event) => {
    setChecked(event.target.checked);
  };

  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }
  }, []);

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 12 }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 800,
              color: "#3F3F46",
              marginBottom: 2,
            }}
          >
            Account settings
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item xs={3}>
          <TextField
            required
            fullWidth
            id="account-type"
            label="Account type:"
            variant="standard"
            value={user ? user[0].type : ""}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            fullWidth
            id="account-email"
            label="Email:"
            value={user ? user[0].email : ""}
            variant="standard"
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={2}
        marginTop={1}
      >
        <Grid item xs={3}>
          <TextField
            required
            fullWidth
            id="account-password"
            label="Password:"
            variant="standard"
            value={user ? user[0].pass : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="primary"
                    color="tertiary"
                    sx={{ color: "#1D4ED8", py: 0, px: 1 }}
                  >
                    Change
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            fullWidth
            id="account-phone"
            label="Phone number:"
            variant="standard"
            value={user ? user[0].phone : ""}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ marginTop: 6, marginBottom: 4 }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 800,
              color: "#3F3F46",
            }}
          >
            Additional email preferences
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
      >
        <Grid item>
          <Checkbox
            value={checked}
            onChange={agreeToEmails}
            sx={{
              color: "#3F3F46",
              "&.Mui-checked": {
                color: "#3F3F46",
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          Receive career advice, tips, announcements, and other marketing emails
          from iSAP Jobs
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ marginTop: 6 }}
      >
        <Button
          variant="primary"
          sx={{ px: 10, fontSize: 16 }}
          disabled={!checked}
        >
          Save
        </Button>
      </Grid>
    </Box>
  );
}
