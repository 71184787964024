import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import Footer from "../components/home/footer";
import NavigationBar from "../components/search/nav-bar";
import EditCV from "../components/user-settings/edit-cv";

export default function UpdateCVPage() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", flexGrow: 1 }}>
      <CssBaseline />
      <NavigationBar account={true} />
      <EditCV />
      <Footer />
    </Box>
  );
}
