import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// icons and logos
import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import LocationIcon from "@mui/icons-material/LocationOn";
import PayIcon from "@mui/icons-material/Paid";

// custom components
import JobTag from "../../search/job-tag";
import SkillsCard from "./skills";

export default function ProjectCard(props) {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: props.isDetailed ? "#E5E5E5" : "transparent",
        p: props.isDetailed ? 2 : 0,
        borderRadius: 0,
      }}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#3F3F46",
                }}
              >
                Front End Developer - Security Clearance
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <JobTag
                    icon={<TimeIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
                    value={"full time"}
                  />
                </Grid>
                <Grid item>
                  <JobTag
                    icon={
                      <LocationIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />
                    }
                    value={"remote only"}
                  />
                </Grid>
                <Grid item>
                  <JobTag
                    icon={<PayIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
                    value={"hourly / $40"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                {Array.from(Array(3)).map((_, index) => (
                  <Grid item key={index}>
                    <SkillsCard name={"Javascript"} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {props.isDetailed ? (
          <Grid item sx={{ paddingRight: 5 }}>
            <Grid container direction="row" spacing={4}>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}
                    >
                      New Request
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#3F3F46",
                      }}
                    >
                      10
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}
                    >
                      Candidates
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#3F3F46",
                      }}
                    >
                      134
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}
                    >
                      Views
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#3F3F46",
                      }}
                    >
                      3456
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  );
}
