import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// custom components
import Main from "../auth/main";

// icons and logos
import logo from "../../assets/logos/home-joy-logo.png";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HomeMain() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const textSmall = useMediaQuery(theme.breakpoints.down("md"));
  const textMedium = useMediaQuery(theme.breakpoints.down("lg"));
  const paddingDimensions = useMediaQuery(theme.breakpoints.down("lg"));
  const marginSmall = useMediaQuery(theme.breakpoints.down("md"));
  const marginMedium = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        position: "relative",
        marginTop: marginSmall ? 7.5 : marginMedium ? 10 : 15,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ px: paddingDimensions ? 4 : 20 }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: textSmall ? 28 : textMedium ? 48 : 64,
              fontWeight: 800,
              color: "#3F3F46",
            }}
          >
            Find interesting IT
          </Typography>
          <Grid container direction="row" spacing={0}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: textSmall ? 28 : textMedium ? 48 : 64,
                  fontWeight: 800,
                  color: "#3F3F46",
                }}
              >
                projects with&nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <Link
                href="https://isap.solutions"
                underline="none"
                sx={{
                  fontSize: textSmall ? 28 : textMedium ? 48 : 64,
                  fontWeight: 800,
                  color: "#1D4ED8",
                  ":hover": {
                    opacity: 0.7,
                  },
                }}
              >
                iSAP&nbsp;
              </Link>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: textSmall ? 28 : textMedium ? 48 : 64,
                  fontWeight: 800,
                  color: "#3F3F46",
                }}
              >
                jobs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Main signUp={false} homePage={true} />
        </Grid>
      </Grid>

      {!isTablet ? (
        <>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              display: "inline-block",
              width: "811px",
              height: "415px",
              background: "#3F3F46",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: -7,
              right: "15%",
            }}
          >
            <img alt={"isap-logo"} src={logo} />
          </div>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
