import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function AdminCandidateCard(props) {
  return (
    <Box sx={{ bgcolor: "#E5E5E5", p: 2 }}>
      <Grid container direction="column" alignItems="stretch" spacing={1}>
        <Grid item container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <img
              src={props.image}
              alt="profile"
              width={100}
              height={100}
              style={{ objectFit: "cover" }}
            />
          </Grid>

          <Grid item xs={6} container direction="column" spacing={0.5}>
            <Grid item>
              <Typography
                sx={{ fontWeight: 700, fontSize: 18, color: "#3F3F46" }}
              >
                {props.fullName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}
              >
                {props.role}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ my: 1 }}
        >
          <Grid item>
            <Button variant="primary" color="tertiary">
              More
            </Button>
          </Grid>
          <Grid item>
            <Button variant="primary">View</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
