import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import NavigationBar from "../components/search/nav-bar";
import Footer from "../components/home/footer";
import Copyright from "../components/navigation/copyright";

export default function CopyrightPage() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", flexGrow: 1 }}>
      <CssBaseline />
      <NavigationBar account={false} />
      <Copyright />
      <Footer />
    </Box>
  );
}
