import * as React from "react";

// components
import Button from "@mui/material/Button";

// icons
import apple from "../../assets/logos/apple.svg";
import facebook from "../../assets/logos/facebook.svg";

// custom components
import GoogleSignInUpButton from "./google-signIn";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SocialMediaAuth(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <GoogleSignInUpButton exists={true} business={props.business} />

      <Button
        variant="tertiary"
        startIcon={<img src={apple} alt="apple" style={{ marginRight: 5 }} />}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          px: isSmall ? 7 : 8.5,
        }}
      >
        {isSmall ? "Sign In" : "Sign In with Apple"}
      </Button>

      <Button
        variant="tertiary"
        startIcon={
          <img src={facebook} alt="facebook" style={{ marginRight: 12 }} />
        }
        sx={{
          width: "100%",
          my: 1.5,
          justifyContent: "flex-start",
          px: isSmall ? 7 : 8.5,
        }}
      >
        {isSmall ? "Sign In" : "Sign In with Facebook"}
      </Button>
    </div>
  );
}
