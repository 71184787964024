import * as React from "react";
import { useNavigate } from "react-router-dom";

// components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function AdminSignIn(props) {
  let navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const signInManually = () => {
    if (email === "admin" && password === "adm1n") {
      // store the user in localStorage
      localStorage.setItem("admin", true);
      navigate("/admin-dashboard");
    }
  };

  return (
    <Box sx={{ px: 4, py: 2 }}>
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Typography sx={{ color: "#3F3F46", fontWeight: 700, fontSize: 18 }}>
            Admin Panel
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" alignItems="stretch" spacing={1}>
        <Grid item>
          <TextField
            required
            fullWidth
            id="email-signin"
            label="Email"
            variant="standard"
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id="password-required"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="standard"
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Button
                onClick={signInManually}
                variant="primary"
                disableElevation
                sx={{
                  marginTop: 2,
                  marginBottom: 1,
                  px: 5,
                  fontSize: 16,
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
