import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// icons and logos
import SearchIcon from "@mui/icons-material/Search";

// custom components
import CandidateCard from "./components/candidate-card";
import PaginationC from "../search/pagination";
import AdminSearchFilter from "./components/search-filter";

// constants
import { API_URL } from "../../constants";

export default function AdminSearchCV() {
  // pagination variables
  const PER_PAGE = 10;

  // rest api variables
  const [total, setTotal] = React.useState(0);
  const [allCV, setAllCV] = React.useState([]);
  const [searchKeyword, setSearchKeyword] = React.useState("");

  React.useEffect(() => {
    // get first page jobs
    const body = {
      page: 1,
      count: PER_PAGE,
    };

    console.log(body);

    axios.post(`${API_URL}/getallcv`, body).then((res) => {
      setAllCV(res.data.result);
      setTotal(res.data.total);
    });
  }, []);

  const changePage = (page, count) => {
    // get first page jobs
    const body = {
      page: page,
      count: count,
    };

    console.log(body);

    axios.post(`${API_URL}/getallcv`, body).then((res) => {
      setAllCV(res.data.result);
      setTotal(res.data.total);
      window.scrollTo(0, 0);
    });
  };

  const searchFor = (keyword) => {
    // axios call & body
    const body = {
      find: keyword,
    };

    console.log(body);

    axios.post(`${API_URL}/searchcandidate`, body).then((res) => {
      console.log(res.data);
      setAllCV(res.data);
    });
  };

  const count = Math.ceil(total / PER_PAGE);

  const handleChange = (event, value) => {
    changePage(value, PER_PAGE);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        paddingLeft: 10,
        paddingRight: 30,
        py: 5,
        height: allCV.length > 5 ? "100%" : "100vh",
      }}
    >
      {allCV.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: "50%",
            left: "55%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              color: "#3F3F46",
              fontWeight: 700,
              fontSize: 18,
              marginTop: 2,
            }}
          >
            {total} CV Found
          </Typography>
          <Typography
            sx={{
              color: "#A3A3A3",
              fontWeight: 500,
              fontSize: 14,
              marginBottom: 2,
            }}
          >
            Showing {PER_PAGE} resumes per page
          </Typography>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Paper elevation={0} sx={{ px: 2, py: 1, borderRadius: 0 }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          variant="standard"
                          onChange={(event) =>
                            setSearchKeyword(event.target.value)
                          }
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => searchFor(searchKeyword)}
                          variant="primary"
                        >
                          <SearchIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <AdminSearchFilter />
                </Grid>
                <Grid item>
                  {allCV.map((_, index) => (
                    <Grid item key={index}>
                      <CandidateCard info={allCV[index]} />
                    </Grid>
                  ))}
                </Grid>
                <Grid item>
                  <PaginationC count={count} handleChange={handleChange} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
