import * as React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// icons and logos
import backarrow from "../../assets/icons/back-arrow.svg";

// custom components
import ParsedFromCV from "./components/parsed-from-cv";

// constants
import { API_URL } from "../../constants";

const steps = [
  "Interviewed by iSAP",
  "Interviewed by Client",
  "Offer sent",
  "Agreement signed",
];

export default function CandidateCV(props) {
  let { userid } = useParams();
  const navigate = useNavigate();
  const [candidateInfo, setCandidateInfo] = React.useState(undefined);

  React.useEffect(() => {
    // get all jobs
    axios
      .get(`${API_URL}/getcandidatebyid/candidate_id=${userid}`)
      .then((res) => {
        setCandidateInfo(res.data[0]);
      });
  }, [userid]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#F5F5F5",
        p: 3,
        height: candidateInfo === undefined ? "100vh" : "100%",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item>
          <Button
            onClick={() => navigate(-1)}
            variant="transparent"
            sx={{ fontWeight: 700, fontSize: 14 }}
            startIcon={
              <img src={backarrow} alt="backarrow" style={{ marginRight: 8 }} />
            }
          >
            Back to search
          </Button>
        </Grid>
        {props.admin ? (
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={2} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {candidateInfo === undefined ? (
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            top: "50%",
            left: "55%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          spacing={4}
          sx={{ my: 1, paddingRight: 5 }}
          alignItems="flex-start"
        >
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justifyContent="stretch"
              spacing={2}
            >
              <Grid item>
                <img
                  style={{ objectFit: "cover" }}
                  src={candidateInfo.image}
                  width={"100%"}
                  height={200}
                  alt="name_photo"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="primary"
                  sx={{ fontWeight: 700, width: "100%" }}
                >
                  Book interview
                </Button>
              </Grid>
              {props.admin ? (
                <Grid item>
                  <Button
                    href={candidateInfo.pdffile}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="secondary"
                    sx={{ fontWeight: 700, width: "100%" }}
                  >
                    Download CV
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item>
                <Button
                  variant="secondary"
                  sx={{ fontWeight: 700, width: "100%" }}
                >
                  View video interview
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <ParsedFromCV info={candidateInfo} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
