import * as React from "react";

// components

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

export default function AdminProjectsCard(props) {
  return (
    <Card sx={{ bgcolor: "#E5E5E5", borderRadius: 0 }} elevation={0}>
      <CardMedia
        component="img"
        height="140"
        image={props.image}
        alt="green iguana"
      />
      <CardContent>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18, color: "#3F3F46" }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}>
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
