import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

export default function BusinessSearchCVFilter() {
  const [continent, setContinent] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [category, setCategory] = React.useState("");

  const handleContinent = (event) => {
    setContinent(event.target.value);
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Paper square elevation={0} sx={{ px: 2 }}>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ py: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography
              sx={{ fontSize: 18, fontWeight: 800, color: "#3F3F46" }}
            >
              Filter your search
            </Typography>
          </Grid>
        </Grid>

        <Divider width="100%" sx={{ my: 1, color: "#F5F5F5" }} />

        <FormControl
          variant="standard"
          fullWidth
          sx={{ my: 1, backgroundColor: "#F5F5F5" }}
        >
          <InputLabel id="demo-simple-select-standard-label">
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
                px: 2,
              }}
            >
              {continent === "" ? "Continent" : ""}
            </Typography>
          </InputLabel>
          <Select
            labelId="continent-label"
            id="continent"
            value={continent}
            onChange={handleContinent}
            disableUnderline
            sx={{
              color: "#3F3F46",
              fontSize: 14,
              fontWeight: "bold",
              px: 2,
            }}
          >
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Asia"}
            >
              Asia
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Africa"}
            >
              Africa
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Europe"}
            >
              Europe
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"North America"}
            >
              North America
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"South America"}
            >
              South America
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Australia"}
            >
              Australia
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Antarctica"}
            >
              Antarctica
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          fullWidth
          sx={{ my: 1, backgroundColor: "#F5F5F5" }}
        >
          <InputLabel id="demo-simple-select-standard-label">
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
                px: 2,
              }}
            >
              {country === "" ? "Country" : ""}
            </Typography>
          </InputLabel>
          <Select
            labelId="country-label"
            id="country"
            value={country}
            onChange={handleCountry}
            disableUnderline
            sx={{
              color: "#3F3F46",
              fontSize: 14,
              fontWeight: "bold",
              px: 2,
            }}
          >
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Germany"}
            >
              Germany
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"France"}
            >
              France
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Sweden"}
            >
              Sweden
            </MenuItem>
          </Select>
        </FormControl>

        <Grid item sx={{ my: 1 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: "#3F3F46" }}>
            Hourly Rate
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            directon="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item>
              <TextField
                id="start"
                placeholder="Start"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{ backgroundColor: "#F5F5F5", px: 2, py: 1 }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="to"
                placeholder="To"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{ backgroundColor: "#F5F5F5", px: 2, py: 1 }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ my: 1 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: "#3F3F46" }}>
            Daily Rate
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            directon="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item>
              <TextField
                id="start"
                placeholder="Start"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{ backgroundColor: "#F5F5F5", px: 2, py: 1 }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="to"
                placeholder="To"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{ backgroundColor: "#F5F5F5", px: 2, py: 1 }}
              />
            </Grid>
          </Grid>
        </Grid>

        <FormControl
          variant="standard"
          fullWidth
          sx={{ my: 2, backgroundColor: "#F5F5F5" }}
        >
          <InputLabel id="demo-simple-select-standard-label">
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
                px: 2,
              }}
            >
              {category === "" ? "Categories" : ""}
            </Typography>
          </InputLabel>
          <Select
            labelId="country-label"
            id="country"
            value={category}
            onChange={handleCategory}
            disableUnderline
            sx={{
              color: "#3F3F46",
              fontSize: 14,
              fontWeight: "bold",
              px: 2,
            }}
          >
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Germany"}
            >
              Category #1
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"France"}
            >
              Category #2
            </MenuItem>
            <MenuItem
              sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
              value={"Sweden"}
            >
              Category #3
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Paper>
  );
}
