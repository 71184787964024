import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function JobTag(props) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>{props.icon}</Grid>
      <Grid item>
        <Typography
          sx={{
            fontWeight: props.makeBold ? "bold" : 500,
            fontSize: 14,
            color: "#3F3F46",
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
}
