import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

// icons and logos
import logo from "../../assets/logos/bottom-isap-logo.svg";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Footer() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const paddingDimensions = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        backgroundColor: "#D4D4D4",
        py: 4,
        px: paddingDimensions ? 4 : 20,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent={!isTablet ? "space-between" : "center"}
        alignItems="center"
      >
        {!isTablet ? (
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <a href="https://isap.solutions">
                  <img
                    alt={"isap-logo"}
                    src={logo}
                    style={{
                      borderRight: "1px solid #3F3F46",
                      paddingRight: 20,
                    }}
                  />
                </a>
              </Grid>
              <Grid item>
                <Button variant="transparent" color="primary">
                  Terms and conditions
                </Button>
              </Grid>
              <Grid item>
                <Button variant="transparent" color="primary">
                  Cookie policy
                </Button>
              </Grid>
              <Grid item>
                <Button variant="transparent" color="primary">
                  Sitemap
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item>
          <Link
            href="/copyright"
            underline="none"
            sx={{
              fontSize: 13,
              fontWeight: 500,
              color: "#3F3F46",
              ":hover": {
                opacity: 0.7,
              },
            }}
          >
            © {new Date().getFullYear()} iSAP. All rights reserved.
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
