import * as React from "react";

// components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SkillsCard(props) {
  console.log(props.name);
  return props.name !== " " ? (
    <Box sx={{ backgroundColor: "white", p: 1 }}>
      <Typography sx={{ color: "#A3A3A3", fontSize: 14, fontWeight: 500 }}>
        {props.name}
      </Typography>
    </Box>
  ) : (
    <></>
  );
}
