import * as React from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import "../../App.css";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

// icons and logos
import camera from "../../assets/icons/camera.svg";
import cloud from "../../assets/icons/cloud.svg";
import kyc from "../../assets/icons/kyc.svg";
import Edit from "@mui/icons-material/Edit";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// custom components
import Employment from "./employment";
import Skill from "./skill";
import Education from "./education";
import Personality from "./personality";

// constants
import { API_URL } from "../../constants";

const Input = styled("input")({
  display: "none",
});

export default function RegisterCVMain() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // loading state
  const [loading, setLoading] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = React.useState(false);

  // textfield controllers
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birth, setBirth] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phonenumber, setPhoneNumber] = React.useState("");

  const [position, setPosition] = React.useState("");

  // employment related array and array mutations
  const [employments, setEmployments] = React.useState([]);
  const addEmployment = () => {
    setEmployments([
      ...employments,
      <Grid item>
        <Employment />
      </Grid>,
    ]);
  };
  const removeEmployment = () => {
    const list = [...employments];
    list.pop();
    setEmployments(list);
  };

  // skills related array and array mutations
  const [skills, setSkills] = React.useState([]);
  const addSkills = () => {
    setSkills([
      ...skills,
      <Grid item>
        <Skill />
      </Grid>,
    ]);
  };
  const removeSkills = () => {
    const list = [...skills];
    list.pop();
    setSkills(list);
  };

  // education related array and array mutations
  const [educations, setEducatons] = React.useState([]);
  const addEducations = () => {
    setEducatons([
      ...educations,
      <Grid item>
        <Education />
      </Grid>,
    ]);
  };
  const removeEducations = () => {
    const list = [...educations];
    list.pop();
    setEducatons(list);
  };

  // uploaded files
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedResume, setSelectedResume] = React.useState(null);
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [selectedKYC, setSelectedKYC] = React.useState(null);
  const [selectedDiplomas, setSelectedDiplomas] = React.useState(null);

  // preview image
  const [imageUrl, setImageUrl] = React.useState(null);

  // sign and agree to tems
  const [checked, setChecked] = React.useState(true);
  const agreeToTerms = (event) => {
    setChecked(event.target.checked);
  };

  // executes on load
  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // on save button click
  async function sendCV() {
    console.log(selectedKYC);
    console.log(selectedDiplomas);

    // axios call & body
    const body = {
      image: await toBase64(selectedImage),
      pdffile: await toBase64(selectedResume),
      name: firstName,
      lastname: lastName,
      dateofbirth: birth.toISOString().split("T")[0],
      Mail: email,
      "Mobile Number": phonenumber,
      role: position,
      postcode: postcode,
      city: city,
      address: address,
      Qualification: "education",
      Employment: "employment",
      Skills: "skills",
      video_intro: selectedVideo,
      // TODO
      Personality: "personality",
      summary1: "summary",
      summary2: "summary",
    };

    console.log(body);

    axios.post(`${API_URL}/saveResume`, body).then((res) => {
      console.log(res);
    });
  }

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 12 }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 800,
              color: "#3F3F46",
              marginBottom: 2,
            }}
          >
            Register your CV
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        alignItems="stretch"
        spacing={2}
        sx={{ px: isTablet ? 5 : 30 }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, color: "#A3A3A3" }}
                  >
                    Your Photo*
                  </Typography>
                </Grid>
                <Grid item>
                  {imageUrl === null ? (
                    <div
                      style={{
                        backgroundColor: "#E5E5E5",
                        height: 200,
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="photo-file-upload">
                        <Input
                          accept="image/*"
                          id="photo-file-upload"
                          type="file"
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          sx={{
                            ":hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <img alt={"camera"} src={camera} />
                        </IconButton>
                      </label>
                    </div>
                  ) : (
                    <div class="profile-pic">
                      <img
                        src={imageUrl}
                        alt={selectedImage.name}
                        width={200}
                        height={200}
                        style={{ objectFit: "cover" }}
                      />
                      <div class="edit">
                        <label htmlFor="photo-file-upload">
                          <Input
                            accept="image/*"
                            id="photo-file-upload"
                            type="file"
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            sx={{
                              ":hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <Avatar sx={{ bgcolor: "white" }}>
                              <Edit sx={{ color: "#1D4ED8" }} />
                            </Avatar>
                          </IconButton>
                        </label>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4.5} xl={5}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1.5}
              >
                <Grid item>
                  <TextField
                    onChange={(event) => setFirstName(event.target.value)}
                    value={firstName}
                    required
                    fullWidth
                    id="firstname-required"
                    label="First Name"
                    variant="standard"
                    InputProps={{
                      endAdornment: loading ? (
                        <InputAdornment position="end">
                          <CircularProgress style={{ padding: "10px" }} />
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => setLastName(event.target.value)}
                    value={lastName}
                    required
                    fullWidth
                    id="lastname-required"
                    label="Last Name"
                    variant="standard"
                    InputProps={{
                      endAdornment: loading ? (
                        <InputAdornment position="end">
                          <CircularProgress style={{ padding: "10px" }} />
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      mask="____-__-__"
                      label="Date of Birth"
                      value={birth}
                      inputFormat="yyyy-MM-dd"
                      onChange={(newValue) => {
                        setBirth(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    required
                    fullWidth
                    id="email-required"
                    label="Email"
                    variant="standard"
                    InputProps={{
                      endAdornment: loading ? (
                        <InputAdornment position="end">
                          <CircularProgress style={{ padding: "10px" }} />
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <label htmlFor="upload-kyc-file">
                    <Input
                      accept="video/*"
                      type="file"
                      id="upload-kyc-file"
                      onChange={(e) => setSelectedKYC(e.target.files[0])}
                    />
                    <Button
                      component="span"
                      variant="secondary"
                      startIcon={<img src={kyc} alt="kyc" />}
                      sx={{ fontSize: 16, width: "100%", marginTop: 2 }}
                    >
                      KYC Verification
                    </Button>
                  </label>
                </Grid>
                <Grid item>
                  <label htmlFor="upload-cv-file">
                    <Input
                      accept="application/pdf"
                      type="file"
                      id="upload-cv-file"
                      onChange={(e) => {
                        setLoading(true);
                        setSelectedResume(e.target.files[0]);

                        let formData = new FormData();

                        formData.append("file", e.target.files[0]);

                        axios
                          .post(`${API_URL}/parsingforweb`, formData)
                          .then((response) => {
                            console.log(response.data);
                            setLoading(false);
                            setFirstName(response.data.name);
                            setLastName(response.data.surname);
                            setEmail(response.data.Mail);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    />
                    <Button
                      component="span"
                      variant="primary"
                      color="secondary"
                      startIcon={<img src={cloud} alt="cloud" />}
                      sx={{
                        fontSize: 16,
                        width: "100%",
                        marginTop: 1,
                        py: 1.5,
                      }}
                    >
                      Upload your CV
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4.5} xl={5}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1.5}
              >
                <Grid item>
                  <TextField
                    onChange={(event) => setPostcode(event.target.value)}
                    required
                    fullWidth
                    id="postcode-required"
                    label="Postcode"
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => setCity(event.target.value)}
                    required
                    fullWidth
                    id="city-required"
                    label="City/Locality"
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => setAddress(event.target.value)}
                    fullWidth
                    id="address"
                    label="Address"
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    required
                    fullWidth
                    id="phonenumber-required"
                    label="Phone Number"
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <label htmlFor="upload-video-file">
                    <Input
                      accept="video/mp4"
                      type="file"
                      id="upload-video-file"
                      onChange={(e) => {
                        setLoadingVideo(true);
                        console.log(e.target.files[0]);
                        let formData = new FormData();

                        formData.append("file", e.target.files[0]);

                        axios
                          .post(`${API_URL}/uploadfiles`, formData)
                          .then((response) => {
                            console.log(response.data.result);
                            setLoadingVideo(false);
                            setSelectedVideo(response.data.result);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    />
                    <Button
                      component="span"
                      variant="secondary"
                      startIcon={<img src={kyc} alt="video" />}
                      sx={{ fontSize: 16, width: "100%", marginTop: 2 }}
                    >
                      Upload Video Intro
                      {loadingVideo ? (
                        <CircularProgress
                          style={{ padding: "12px", color: "white" }}
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ marginTop: 3 }}>
          <TextField
            onChange={(event) => setPosition(event.target.value)}
            required
            fullWidth
            id="position-required"
            label="Position"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="looking-for"
            label="What are you looking for"
            variant="standard"
            multiline
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="prof-summary"
            label="Professional Summary"
            variant="standard"
            multiline
          />
        </Grid>

        {/* employment */}
        <Grid item sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#3F3F46",
            }}
          >
            Employment
          </Typography>
        </Grid>
        <Grid item>
          <Employment />
        </Grid>
        {employments}
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                onClick={addEmployment}
                variant="secondary"
                sx={{ px: 4, marginTop: 1 }}
              >
                Add
              </Button>
            </Grid>
            {employments.length === 0 ? (
              <></>
            ) : (
              <Grid item>
                <Button
                  onClick={removeEmployment}
                  variant="primary"
                  color="secondary"
                  sx={{ px: 4, my: 1 }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* skills */}
        <Grid item sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#3F3F46",
            }}
          >
            Skills
          </Typography>
        </Grid>
        <Grid item>
          <Skill />
        </Grid>
        {skills}
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                onClick={addSkills}
                variant="secondary"
                sx={{ px: 4, marginTop: 1 }}
              >
                Add
              </Button>
            </Grid>
            {skills.length === 0 ? (
              <></>
            ) : (
              <Grid item>
                <Button
                  onClick={removeSkills}
                  variant="primary"
                  color="secondary"
                  sx={{ px: 4, my: 1 }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* education */}
        <Grid item sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#3F3F46",
            }}
          >
            Education
          </Typography>
        </Grid>
        <Grid item>
          <Education />
        </Grid>
        {educations}
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                onClick={addEducations}
                variant="secondary"
                sx={{ px: 4, marginTop: 1 }}
              >
                Add
              </Button>
            </Grid>
            {educations.length === 0 ? (
              <></>
            ) : (
              <Grid item>
                <Button
                  onClick={removeEducations}
                  variant="primary"
                  color="secondary"
                  sx={{ px: 4, my: 1 }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* diplomas */}
        <Grid item sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#3F3F46",
            }}
          >
            Diplomas, certificates and other documents
          </Typography>
        </Grid>
        <Grid item>
          <label htmlFor="upload-diplomas-file">
            <Input
              accept="application/pdf"
              type="file"
              id="upload-diplomas-file"
              onChange={(e) => setSelectedDiplomas(e.target.files[0])}
            />
            <Button
              component="span"
              variant="primary"
              color="secondary"
              startIcon={<img src={cloud} alt="cloud" />}
              sx={{
                fontSize: 16,
                width: "100%",
                marginTop: 1,
                py: 2,
              }}
            >
              Upload documents
            </Button>
          </label>
        </Grid>

        {/* personality */}
        <Grid item sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#3F3F46",
            }}
          >
            Personality
          </Typography>
        </Grid>
        <Grid item>
          <Personality />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 8 }}
      >
        <Grid item>
          <Checkbox value={checked} onChange={agreeToTerms} />
        </Grid>
        <Grid item>I agree and sign the agreement</Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ marginTop: 4, marginBottom: 10 }}
      >
        <Button
          onClick={sendCV}
          variant="primary"
          sx={{ px: 15, py: 1.5, fontSize: 16 }}
          disabled={!checked}
        >
          Save
        </Button>
      </Grid>
    </Box>
  );
}
