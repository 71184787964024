import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// icons and logos
import forname from "../../../assets/icons/for-name.svg";
import fortime from "../../../assets/icons/for-time.svg";
import forplace from "../../../assets/icons/for-place.svg";
import FlagIcon from "@mui/icons-material/Flag";

// custom components
import JobTag from "../../search/job-tag";
import SkillsCard from "../../business-views/components/skills";

export default function CandidateCard(props) {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#E5E5E5", p: 2, borderRadius: 0, my: 2 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item>
              <img
                src={props.info.image}
                alt="name_photo"
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    sx={{ color: "#3F3F46", fontSize: 18, fontWeight: "bold" }}
                  >
                    {props.info.role}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={3}>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={forname}
                            alt="for-name"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={props.info.firstname + " " + props.info.lastname}
                        makeBold={true}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <FlagIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />
                        }
                        value={props.info.city}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={fortime}
                            alt="for-time"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"full time"}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={forplace}
                            alt="for-place"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"remote only"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: 1 }}>
                  {props.info.skills !== "" ? (
                    <Grid container direction="row" spacing={1}>
                      {props.info.skills
                        .split(",")
                        .slice(0, props.admin ? 6 : 3)
                        .map((_, index) => (
                          <Grid item key={index}>
                            <SkillsCard
                              name={props.info.skills.split(",")[index]}
                            />
                          </Grid>
                        ))}
                      {props.info.skills.split(",").length > 3 ? (
                        <Grid item>
                          <SkillsCard
                            name={`+${props.info.skills.split(",").length - 3}`}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ) : (
                    <Typography
                      sx={{ color: "#A3A3A3", fontSize: 14, fontWeight: 700 }}
                    >
                      No skills found.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="stretch" spacing={1}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Button
                  href={`/admin-dashboard/search-cv/${props.info.candidate_id}`}
                  variant="tertiary"
                  sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
                >
                  Read CV
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="secondary"
                  color="reverse"
                  sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="secondary"
                sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
              >
                Book Interview
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
