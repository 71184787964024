import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

// icons and logos
import CloseIcon from "@mui/icons-material/Close";

// custom components
import SocialMediaAuth from "./social-media-signIn";

// constants
import { API_URL } from "../../constants";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignInForm() {
  let navigate = useNavigate();

  // loading state
  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const signInManually = () => {
    setLoading(true);
    // axios call & body
    const body = {
      email: email,
      pass: password,
    };

    console.log(body);

    axios.post(`${API_URL}/signin`, body).then((res) => {
      setLoading(false);
      console.log(res.data);

      if (res.data.length !== 0) {
        setSuccessSnackbar(true);
        // store the user in localStorage
        localStorage.setItem("user", JSON.stringify(res.data));
        navigate("/search-jobs");
      } else {
        setErrorSnackbar(true);
      }
    });
  };

  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbar(false);
  };

  return (
    <Box sx={{ px: 4, py: 2 }}>
      <Grid container direction="column" alignItems="stretch" spacing={1}>
        <Grid item>
          <TextField
            required
            fullWidth
            id="email-signin"
            label="Email"
            variant="standard"
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id="password-required"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="standard"
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Button
                onClick={signInManually}
                variant="primary"
                disableElevation
                sx={{
                  marginTop: 2,
                  marginBottom: 1,
                  px: 5,
                  fontSize: 16,
                }}
              >
                Sign In
                {loading ? (
                  <CircularProgress
                    style={{ padding: "12px", color: "white" }}
                  />
                ) : (
                  <></>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Link
                href="/recover-password"
                underline="none"
                sx={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#3F3F46",
                  cursor: "pointer",
                  ":hover": {
                    opacity: 0.7,
                  },
                }}
              >
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ width: "100%" }}>
          <Divider spacing={1} sx={{ marginTop: 3 }}>
            <Typography
              sx={{ fontWeight: "normal", fontSize: 15, color: "gray" }}
            >
              OR
            </Typography>
          </Divider>
        </Grid>

        <SocialMediaAuth />
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSuccessClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully signed in!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleErrorClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error signing in
        </Alert>
      </Snackbar>
    </Box>
  );
}
