// react
import * as React from "react";

// components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// icons and logos
import backarrow from "../../assets/icons/back-arrow-navbar.svg";
import logo from "../../assets/logos/isap-logo.svg";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SimpleAuthNavigationBar(props) {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // user interface
  return (
    <Box sx={{ flexGrow: 1, maxHeight: 20 }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "transparent", py: 1, px: isTablet ? 0 : 15 }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {isMobile ? (
                <IconButton
                  href="/"
                  disableElevation
                  sx={{
                    color: "#A3A3A3",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: 20,
                  }}
                >
                  <img src={backarrow} alt="backarrow" />
                </IconButton>
              ) : (
                <Button
                  href="/"
                  startIcon={<img src={backarrow} alt="backarrow" />}
                  variant="transparent"
                  color="secondary"
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Back to Home
                </Button>
              )}
            </Grid>
            <Grid item>
              <a href="/">
                <img
                  alt={"isap-logo"}
                  src={logo}
                  width="100%"
                  sx={{
                    textAlign: "center",
                  }}
                />
              </a>
            </Grid>
            <Grid item>
              <Button
                href={props.business ? "/sign-up" : "/sign-up/business"}
                variant="primary"
                sx={{ px: 3 }}
              >
                {props.business ? "Developer Login" : "Business Clients"}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
