import * as React from "react";
import RecoveryPasswordPage from "./pages/recover-password-page";
import HomePage from "./pages/home-page";
import { Routes, Route, Navigate } from "react-router-dom";
import SearchPage from "./pages/search-page";
import RegisterCVPage from "./pages/register-cv-page";
import BusinessPage from "./pages/business-page";
import BusinessHome from "./components/business-views/home";
import BusinessSearchCV from "./components/business-views/search-cv";
import BusinessProjects from "./components/business-views/projects";
import CandidateCV from "./components/business-views/candidate-cv";
import ProjectDetailed from "./components/business-views/project-detailed";
import SignUpPage from "./pages/sign-up-page";
import SignInPage from "./pages/sign-in-page";
import AccountSettings from "./pages/account-settings-page";
import AccountCV from "./pages/account-cv-page";
import AdminPage from "./pages/admin-page";
import AdminHome from "./components/admin-views/home";
import AdminSearchCV from "./components/admin-views/search-cv";
import AdminLogin from "./pages/admin-login-page";
import AdminBookedInterviews from "./components/admin-views/booked-interviews";
import AdminSearchProjects from "./components/admin-views/search-projects";
import BusinessSignUpPage from "./pages/business-sign-up-page";
import BusinessSignInPage from "./pages/business-sign-in-page";
import UpdateCVPage from "./pages/update-cv-page";
import CopyrightPage from "./pages/copyright-page";

function App() {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={user ? <Navigate to="/search-jobs" /> : <HomePage />}
        />
        <Route path="/copyright" element={<CopyrightPage />} />
        <Route path="/admin/auth/login" element={<AdminLogin />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up/business" element={<BusinessSignUpPage />} />
        <Route path="/sign-in/business" element={<BusinessSignInPage />} />
        <Route path="/recover-password" element={<RecoveryPasswordPage />} />
        <Route path="/search-jobs" element={<SearchPage />} />
        <Route path="/register-cv" element={<RegisterCVPage />} />
        <Route path="/my-account/settings" element={<AccountSettings />} />
        <Route path="/my-account/resume" element={<AccountCV />} />
        <Route path="my-account/update-resume" element={<UpdateCVPage />} />
        <Route
          path="/business-dashboard"
          element={<BusinessPage index={0} option={<BusinessHome />} />}
        />
        <Route
          path="/business-dashboard/notifications"
          element={<BusinessPage index={1} option={<div />} />}
        />
        <Route
          path="/business-dashboard/search-cv"
          element={<BusinessPage index={2} option={<BusinessSearchCV />} />}
        />
        <Route
          path="/business-dashboard/search-cv/:userid"
          element={
            <BusinessPage index={2} option={<CandidateCV admin={false} />} />
          }
        />
        <Route
          path="/business-dashboard/projects"
          element={<BusinessPage index={3} option={<BusinessProjects />} />}
        />
        <Route
          path="/business-dashboard/projects/projectid"
          element={<BusinessPage index={3} option={<ProjectDetailed />} />}
        />
        <Route
          path="/business-dashboard/messenger"
          element={<BusinessPage index={4} option={<div />} />}
        />
        <Route
          path="/business-dashboard/calendar"
          element={<BusinessPage index={5} option={<div />} />}
        />
        <Route
          path="/business-dashboard/settings"
          element={<BusinessPage index={6} option={<div />} />}
        />
        <Route
          path="/admin-dashboard"
          element={<AdminPage index={0} option={<AdminHome />} />}
        />
        <Route
          path="/admin-dashboard/booked-interviews"
          element={<AdminPage index={1} option={<AdminBookedInterviews />} />}
        />
        <Route
          path="/admin-dashboard/search-cv"
          element={<AdminPage index={2} option={<AdminSearchCV />} />}
        />
        <Route
          path="/admin-dashboard/search-cv/:userid"
          element={
            <AdminPage index={2} option={<CandidateCV admin={true} />} />
          }
        />
        <Route
          path="/admin-dashboard/projects"
          element={<AdminPage index={3} option={<AdminSearchProjects />} />}
        />
      </Routes>
    </div>
  );
}

export default App;
