import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

// icons and logos
import download from "../../assets/icons/download.svg";
import edit from "../../assets/icons/edit.svg";
import verified from "../../assets/icons/verified.svg";
import play from "../../assets/icons/play.svg";
import temp from "../../assets/logos/temp.svg";

// custom components
import Employment from "./components/employment";
import Skills from "./components/skills";
import NameDate from "./components/name-date";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// constants
import { API_URL } from "../../constants";

export default function UserCV() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [user, setUser] = React.useState();
  const [candidateInfo, setCandidateInfo] = React.useState();

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);

      axios
        .get(`${API_URL}/getcandidatebyemail/${foundUser[0].email}`)
        .then((res) => {
          console.log(res.data);
          setCandidateInfo(res.data);
        });
    }
  }, []);

  return !candidateInfo ? (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 12 }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </>
  ) : (
    <Box sx={{ px: isMobile ? 0 : 20 }}>
      {candidateInfo.length === 0 ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{
              paddingTop: 12,
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: 17,
                  fontWeight: 500,
                  color: "#3F3F46",
                  marginBottom: 2,
                }}
              >
                We couldn't find your CV. Please register your CV.
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid item>
                <Button variant="secondary" href="/search-jobs">
                  View jobs
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary" href="/register-cv">
                  Register CV
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{ paddingTop: 12 }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: 17,
                  fontWeight: 800,
                  color: "#3F3F46",
                  marginBottom: 2,
                }}
              >
                Your CV
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{ marginBottom: 10 }}
          >
            <Grid item xs={8}>
              <Box sx={{ bgcolor: "white", p: 4 }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="stretch"
                          spacing={2}
                        >
                          <Grid item>
                            <img src={temp} alt="profile_picture" />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="primary"
                              color="tertiary"
                              sx={{ width: "100%" }}
                              startIcon={<img src={verified} alt="verified?" />}
                            >
                              KYC
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="primary"
                              color="tertiary"
                              sx={{ width: "100%" }}
                              startIcon={<img src={play} alt="press_play" />}
                            >
                              Video Intro
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sx={{ px: 5 }} xs={isMobile ? 12 : 8}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: 18,
                                color: "#3F3F46",
                              }}
                            >
                              {user ? user[0].name : ""}{" "}
                              {user ? user[0].lastname : ""}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: 14,
                                color: "#3F3F46",
                              }}
                            >
                              {candidateInfo ? candidateInfo[0].role : ""}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#3F3F46",
                              }}
                              lineHeight={1.25}
                            >
                              {candidateInfo ? candidateInfo[0].city : ""},{" "}
                              {candidateInfo ? candidateInfo[0].postcode : ""}
                              <br />
                              {user ? user[0].email : ""}
                              <br />
                              {user ? user[0].phone : ""}{" "}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#3F3F46",
                              }}
                            >
                              I'm looking for a company where I can grow
                              professionally, and will be able to use all my
                              professional skills. Prefer companies with
                              in-house projects. Love teamwork and difficult
                              tasks. I'm interested in remote positions, my
                              current position is Ivano-Frankivsk, Ukraine.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* divider */}
                  <Grid item sx={{ my: 2 }}>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}
                    >
                      Professional Summary
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}
                    >
                      Proficient and creative WordPress developer with a broad
                      experience in website management and development. Highly
                      trained in web and mobile development as well as with
                      creating UI/UX design. Experienced in SEO and PPC campaign
                      creation and speed optimization. Expert in all aspects of
                      WordPress website creation, inclding design, plg-ins,
                      APIs, and implementation of third-party services.
                      Responsible for the architectre, design, and development
                      of high volme web service applications. Detail-oriented
                      and knowledgeable in PHP, JS, HTML, CSS. Always open to
                      improvements and new technologies.
                    </Typography>
                  </Grid>
                  {/* divider */}
                  <Grid item sx={{ my: 2 }}>
                    <Divider />
                  </Grid>

                  {/* employment */}
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}
                    >
                      Employment
                    </Typography>
                  </Grid>

                  {Array.from(Array(3)).map((_, index) => (
                    <Grid item key={index}>
                      <Employment
                        name={"UpWork – freelance"}
                        dates={"04/2017 – 02/2019"}
                        descriptions={[
                          "Creating new aggregator sites in Top Rated 10 ecosystem",
                          "Development and implementation of new features and plug-ins",
                          "Support, SEO, and speed optimization of existing sites (now six sites)",
                          "Creation and implementation of A/B testing",
                          "Integration with third-party services, creating reports for offline conversions for Google, Bing, and Yandex",
                        ]}
                      />
                    </Grid>
                  ))}

                  {/* divider */}
                  <Grid item sx={{ my: 2 }}>
                    <Divider />
                  </Grid>

                  {/* skills */}
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}
                    >
                      Skills
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      {Array.from(Array(3)).map((_, index) => (
                        <Grid item xs={6} key={index}>
                          <Skills
                            category={"Front-end"}
                            skills={[
                              "HTML",
                              "CSS (flexbox, grid) with preprocessors",
                              "AMP",
                              "JavaScript / jQuery",
                              "Ajax / XMLHttpRequest / CURL",
                              "JSON / XML / CSV",
                              "Sessions, Cookies, Local Storage",
                            ]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {/* divider */}
                  <Grid item sx={{ my: 2 }}>
                    <Divider />
                  </Grid>

                  {/* education */}
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}
                    >
                      Education
                    </Typography>
                  </Grid>

                  {Array.from(Array(2)).map((_, index) => (
                    <Grid item key={index}>
                      <NameDate
                        name={
                          "University of Banking of the National Bank of Ukraine, Lviv, Ukraine"
                        }
                        dates={"2009 – 2012"}
                      />
                    </Grid>
                  ))}

                  {/* divider */}
                  <Grid item sx={{ my: 2 }}>
                    <Divider />
                  </Grid>

                  {/* personal details */}
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}
                    >
                      Personal Details
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}
                    >
                      Date of birth: 25.02.1992 <br />
                      Languages: English – intermediate
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Button
                    href={candidateInfo ? candidateInfo[0].pdffile : ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="primary"
                    sx={{ width: "100%", px: 6 }}
                    startIcon={<img src={download} alt="download" />}
                  >
                    Download Your CV
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    href="/my-account/update-resume"
                    variant="secondary"
                    sx={{ width: "100%", px: 6 }}
                    startIcon={<img src={edit} alt="edit" />}
                  >
                    Edit Your CV
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
