import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function NameDate(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}>
          {props.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}>
          {props.dates}
        </Typography>
      </Grid>
    </Grid>
  );
}
