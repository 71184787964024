import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ExperienceCard(props) {
  return (
    <Box sx={{ backgroundColor: "#E5E5E5", p: 2 }}>
      <Grid container direction="column" spacing={1}>
        {/* date */}
        <Grid item>
          <Typography
            sx={{ color: "#3F3F46", fontWeight: "bold", fontSize: 14 }}
          >
            {props.interval}
          </Typography>
        </Grid>
        {/* title */}
        <Grid item>
          <Typography
            sx={{ color: "#3F3F46", fontWeight: "bold", fontSize: 18 }}
          >
            {props.main}
          </Typography>
        </Grid>
        {/* subtitle */}
        <Grid item>
          <Typography sx={{ color: "#3F3F46", fontWeight: 500, fontSize: 14 }}>
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
