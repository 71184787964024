import * as React from "react";
import { useNavigate } from "react-router-dom";

// components
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// icons and logos
import home from "../../assets/icons/ab-home-dark.svg";
import homeWhite from "../../assets/icons/ab-home-white.svg";
import notifications from "../../assets/icons/ab-notifications-dark.svg";
import notificationsWhite from "../../assets/icons/ab-notifications-white.svg";
import search from "../../assets/icons/ab-search-dark.svg";
import searchWhite from "../../assets/icons/ab-search-white.svg";
import projects from "../../assets/icons/ab-projects-dark.svg";
import projectsWhite from "../../assets/icons/ab-projects-white.svg";
import messages from "../../assets/icons/ab-messages-dark.svg";
import messagesWhite from "../../assets/icons/ab-messages-white.svg";
import calendar from "../../assets/icons/ab-calendar-dark.svg";
import calendarWhite from "../../assets/icons/ab-calendar-white.svg";
import settings from "../../assets/icons/ab-settings-dark.svg";
import settingsWhite from "../../assets/icons/ab-settings-white.svg";
import agreements from "../../assets/icons/ab-agreements-dark.svg";
import agreementsWhite from "../../assets/icons/ab-agreements-white.svg";
import offers from "../../assets/icons/ab-offer-dark.svg";
import offersWhite from "../../assets/icons/ab-offer-white.svg";
import moderation from "../../assets/icons/ab-moderation-dark.svg";
import moderationWhite from "../../assets/icons/ab-moderation-white.svg";
import createAdmin from "../../assets/icons/ab-createadmin-dark.svg";
import createAdminWhite from "../../assets/icons/ab-createadmin-white.svg";
import logout from "../../assets/icons/logout.svg";
import logo from "../../assets/logos/isap-logo-dark.svg";

export default function DrawerContent(props) {
  let navigate = useNavigate();

  const businessOptions = [
    {
      name: "Home",
      iconBlack: <img src={home} alt="home" />,
      iconWhite: <img src={homeWhite} alt="home" />,
    },
    {
      name: "Notifications",
      iconBlack: <img src={notifications} alt="notifications" />,
      iconWhite: <img src={notificationsWhite} alt="notifications" />,
    },
    {
      name: "Search CV",
      iconBlack: <img src={search} alt="search" />,
      iconWhite: <img src={searchWhite} alt="search" />,
    },
    {
      name: "Projects",
      iconBlack: <img src={projects} alt="projects" />,
      iconWhite: <img src={projectsWhite} alt="projects" />,
    },
    {
      name: "Messages",
      iconBlack: <img src={messages} alt="messages" />,
      iconWhite: <img src={messagesWhite} alt="messages" />,
    },
    {
      name: "Calendar",
      iconBlack: <img src={calendar} alt="calendar" />,
      iconWhite: <img src={calendarWhite} alt="calendar" />,
    },
    {
      name: "Settings",
      iconBlack: <img src={settings} alt="settings" />,
      iconWhite: <img src={settingsWhite} alt="settings" />,
    },
  ];

  const adminOptions = [
    {
      name: "Home",
      iconBlack: <img src={home} alt="home" />,
      iconWhite: <img src={homeWhite} alt="home" />,
    },
    {
      name: "Booked interviews",
      iconBlack: <img src={notifications} alt="notifications" />,
      iconWhite: <img src={notificationsWhite} alt="notifications" />,
    },
    {
      name: "Search CV",
      iconBlack: <img src={search} alt="search" />,
      iconWhite: <img src={searchWhite} alt="search" />,
    },
    {
      name: "Projects",
      iconBlack: <img src={projects} alt="projects" />,
      iconWhite: <img src={projectsWhite} alt="projects" />,
    },
    {
      name: "Messages",
      iconBlack: <img src={messages} alt="messages" />,
      iconWhite: <img src={messagesWhite} alt="messages" />,
    },
    {
      name: "Calendar",
      iconBlack: <img src={calendar} alt="calendar" width="40%" />,
      iconWhite: <img src={calendarWhite} alt="calendar" />,
    },
    {
      name: "Agreements",
      iconBlack: <img src={agreements} alt="agreements" width="35%" />,
      iconWhite: <img src={agreementsWhite} alt="agreements" width="35%" />,
    },
    {
      name: "Offers",
      iconBlack: <img src={offers} alt="offers" />,
      iconWhite: <img src={offersWhite} alt="offers" />,
    },
    {
      name: "CV Moderation",
      iconBlack: <img src={moderation} alt="moderation" width="40%" />,
      iconWhite: <img src={moderationWhite} alt="moderation" width="40%" />,
    },
    {
      name: "Create Admin Role",
      iconBlack: <img src={createAdmin} alt="create-admin" width="40%" />,
      iconWhite: <img src={createAdminWhite} alt="create-admin" width="40%" />,
    },
    {
      name: "Settings",
      iconBlack: <img src={settings} alt="settings" />,
      iconWhite: <img src={settingsWhite} alt="settings" />,
    },
  ];

  const renderUI = (index) => {
    if (props.type) {
      console.log("true");
      switch (index) {
        case 0:
          navigate("/admin-dashboard");
          break;
        case 1:
          navigate("/admin-dashboard/booked-interviews");
          break;
        case 2:
          navigate("/admin-dashboard/search-cv");
          break;
        case 3:
          navigate("/admin-dashboard/projects");
          break;
        default:
          break;
      }
    } else {
      console.log("false");
      switch (index) {
        case 0:
          navigate("/business-dashboard");
          break;
        case 1:
          navigate("/business-dashboard/notifications");
          break;
        case 2:
          navigate("/business-dashboard/search-cv");
          break;
        case 3:
          navigate("/business-dashboard/projects");
          break;
        case 4:
          navigate("/business-dashboard/messenger");
          break;
        case 5:
          navigate("/business-dashboard/calendar");
          break;
        case 6:
          navigate("/business-dashboard/settings");
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box sx={{ backgroundColor: "#3F3F46", color: "white", height: "100vh" }}>
      <Grid container direction="row" justifyContent="center">
        <Grid item sx={{ py: 4 }}>
          <a href="/">
            <img alt={"isap-logo"} src={logo} />
          </a>
        </Grid>
      </Grid>

      <List>
        {props.type
          ? adminOptions.map((item, index) => (
              <ListItem
                button
                key={item.name}
                onClick={() => renderUI(index)}
                sx={{
                  marginLeft: 2,
                  backgroundColor:
                    props.index === index ? "#27272A" : "#3F3F46",
                  ":hover": {
                    backgroundColor:
                      props.index === index ? "#27272A" : "#3F3F46",
                  },
                }}
              >
                <ListItemIcon>
                  {props.index === index ? item.iconWhite : item.iconBlack}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))
          : businessOptions.map((item, index) => (
              <ListItem
                button
                key={item.name}
                onClick={() => renderUI(index)}
                sx={{
                  marginLeft: 2,
                  backgroundColor:
                    props.index === index ? "#27272A" : "#3F3F46",
                  ":hover": {
                    backgroundColor:
                      props.index === index ? "#27272A" : "#3F3F46",
                  },
                }}
              >
                <ListItemIcon>
                  {props.index === index ? item.iconWhite : item.iconBlack}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
      </List>
      <Button
        href="/"
        onClick={() => localStorage.clear()}
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          left: "50%",
          marginLeft: "-50%",
          color: "white",
          backgroundColor: "#27272A",
          textTransform: "none",
          fontSize: 14,
          py: 1,
          fontWeight: 500,
          borderRadius: 0,
          ":hover": {
            backgroundColor: "#27272A",
            opacity: 0.7,
          },
        }}
        startIcon={
          <img src={logout} alt="logout" style={{ marginRight: 10 }} />
        }
      >
        Logout
      </Button>
    </Box>
  );
}
