import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

// custom components
import ProjectCard from "./components/project-card";

export default function BusinessProjects() {
  return (
    <Box sx={{ backgroundColor: "#F5F5F5", px: 5, py: 2.5, height: "100vh" }}>
      <Button
        sx={{
          backgroundColor: "#1D4ED8",
          color: "white",
          textTransform: "none",
          ":hover": {
            backgroundColor: "#1D4ED8",
          },
          fontSize: 14,
          fontWeight: "bold",
          borderRadius: 0,
          py: 1,
          px: 2,
        }}
      >
        Add new project
      </Button>
      <Grid container direction="row">
        <Grid item xs={10}>
          <Grid container direction="column" spacing={2} sx={{ my: 2 }}>
            {Array.from(Array(3)).map((_, index) => (
              <Grid item key={index}>
                <Card elevation={0}>
                  <CardActionArea href="/business-dashboard/projects/projectid">
                    <ProjectCard isDetailed={true} />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
