import * as React from "react";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// custom components
import Footer from "../components/home/footer";
import NavigationBar from "../components/search/nav-bar";
import UserCV from "../components/user-settings/user-cv";

export default function AccountCV() {
  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <NavigationBar account={true} />
      <div style={{ flex: 1 }}>
        <UserCV />
      </div>
      <Footer />
    </Box>
  );
}
