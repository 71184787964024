// react
import * as React from "react";
import { useNavigate } from "react-router-dom";

// components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";

// icons and logos
import logo from "../../assets/logos/isap-logo.svg";
import DropdownIcon from "@mui/icons-material/ArrowDropDown";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function NavigationBar(props) {
  let navigate = useNavigate();

  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [user, setUser] = React.useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleYourCV = () => {
    setAnchorEl(null);
    navigate("/my-account/resume");
  };

  const handleSettings = () => {
    setAnchorEl(null);
    navigate("/my-account/settings");
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    localStorage.clear();
    navigate("/");
  };

  React.useEffect(() => {
    if (props.account) {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        setUser(foundUser);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // user interface
  return (
    <Box sx={{ flexGrow: 1, maxHeight: 20 }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "#E5E5E5", py: 1, px: isMobile ? 0 : 10 }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <a href="/">
                <img alt={"isap-logo"} src={logo} width="100%" />
              </a>
            </Grid>

            {props.account ? (
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Avatar sx={{ bgcolor: "#1D4ED8", marginRight: 2 }}>
                          {user ? (
                            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                              {user[0].name[0] + user[0].lastname[0]}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                              NS
                            </Typography>
                          )}
                        </Avatar>
                      </Grid>
                      <Grid item>
                        {user ? (
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#3F3F46",
                            }}
                          >
                            {user[0].name + " " + user[0].lastname}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#3F3F46",
                            }}
                          >
                            Name Surname
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                      >
                        <DropdownIcon sx={{ color: "#3F3F46" }} />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        sx={{
                          "& .MuiPaper-root": {
                            backgroundColor: "#D4D4D4",
                            borderRadius: 0,
                            marginTop: 2,
                          },
                        }}
                      >
                        <MenuItem
                          onClick={handleYourCV}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#3F3F46",
                            }}
                          >
                            Your CV
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleSettings}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#3F3F46",
                            }}
                          >
                            Account settings
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleLogOut();
                          }}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#3F3F46",
                            }}
                          >
                            Log Out
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#3F3F46",
                      }}
                    >
                      EN
                    </Typography>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "#D4D4D4",
                        borderRadius: 0,
                      },
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "#3F3F46",
                        }}
                      >
                        EN
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "#3F3F46",
                        }}
                      >
                        GE
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
