import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Skills(props) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography sx={{ fontWeight: 700, fontSize: 14, color: "#3F3F46" }}>
          {props.category}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: 500, fontSize: 14, color: "#3F3F46" }}>
          {props.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
}
