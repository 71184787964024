import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// icons and logos
import NameIcon from "@mui/icons-material/BookmarkBorder";
import FlagIcon from "@mui/icons-material/Flag";
import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import LocationIcon from "@mui/icons-material/LocationOn";

// custom components
import JobTag from "../../search/job-tag";
import SkillsCard from "./skills";
import ExperienceCard from "./experience-card";

export default function ParsedFromCV(props) {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}>
          {props.info.role}
        </Typography>
      </Grid>
      <Grid item>
        <JobTag
          icon={<NameIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
          value={props.info.firstname + " " + props.info.lastname}
          makeBold={true}
        />
      </Grid>
      <Grid item>
        <JobTag
          icon={<FlagIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
          value={props.info.city + ", " + props.info.postcode}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <JobTag
              icon={<TimeIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
              value={"full time"}
            />
          </Grid>
          <Grid item>
            <JobTag
              icon={<LocationIcon sx={{ color: "#A3A3A3", fontSize: 14 }} />}
              value={"remote only"}
            />
          </Grid>
        </Grid>

        {/* keywords */}
        <Grid item sx={{ marginTop: 2 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}
          >
            Skills
          </Typography>
        </Grid>
        <Grid item sx={{ my: 1 }}>
          <Grid container direction="row" spacing={1}>
            {props.info.skills.split(",").map((_, index) => (
              <Grid item key={index}>
                <SkillsCard name={props.info.skills.split(",")[index]} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* skills */}
        <Grid item sx={{ marginTop: 2 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}
          >
            Professional Summary
          </Typography>
        </Grid>
        <Grid item sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#3F3F46",
              whiteSpace: "pre-wrap",
            }}
          >
            I am a Certified Enterprise Architect , cloud technologist and a
            product leader for Strategy & Digital transformations .A result
            oriented professional with deep technical & strategic experience in
            building new products to market, optimizing early-stage propositions
            for product-market fit,streamlining ops and managing marketplaces
            for SaaS,BPM, B2B2C product portfolios, enjoys collaborating with
            broad engineering teams,domain exp,customers,sales & executive
            leadership. Previously lead high-performing product engineering
            teams & strategic pilot implementations at big corporations like
            Cerner, Wipro, Unisys & Cognizant in the following areas. ♦
            Enterprise Architecture - Reference implementations | Solution
            Blueprints | Microservices | TOGAF ♦ SaaS Product Life Cycle -
            Concept to Production | Fit for Purpose | Build vs Buy | MVP |
            Subscription ♦ Product and Cloud Strategy - Discovery Phase | Cloud
            Assessment | Partner Ecosystem | Licensing ♦ Agile Product
            Engineering - Author Roadmaps | Prioritize Backlogs | DevOps |
            Release Process ♦ Full Stack development - Java | Spring | Angular |
            Node | Kafka | Python | Kubernetes ♦ Embedded Integrations - AI | ML
            | IoT | RPA | BI | Blockchain | BPMN | ELK | Datalakes ♦ Technical
            Advisor - Presales | GTM | OEM | Partnership | Acquisitions |
            Pricing Certified AWS Solutions Architect Professional with Bachelor
            of Engineering in Electronics &Communication (2003) AWS Certified
            Solutions Architect- Professional with Strong technical experience
            in building SaaS,Serverless and Event driven based full stack
            architectures using micro-services,Container managers (EKS, ECS,
            Kubernetes) on AWS) and GCP
          </Typography>
        </Grid>

        {/* employment */}
        <Grid item sx={{ marginTop: 2 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}
          >
            Employment
          </Typography>
        </Grid>
        {Array.from(Array(2)).map((_, index) => (
          <Grid item sx={{ my: 2 }} key={index}>
            <ExperienceCard
              interval={"02/2017 - 10/2020"}
              main={
                "ENTERPRISE ARCHITECT, SOLUTIONS ARCHITECT, JAVA CLOUD ARCHITECT, PRODUCT MANAGER"
              }
              description={
                "Exceego infolabs / Internet and Information Technology"
              }
            />
          </Grid>
        ))}

        {/* education */}
        <Grid item sx={{ marginTop: 2 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}
          >
            Education
          </Typography>
        </Grid>
        {Array.from(Array(1)).map((_, index) => (
          <Grid item sx={{ my: 2 }} key={index}>
            <ExperienceCard
              interval={"02/2014 - 10/2016"}
              main={"Java development course"}
              description={
                "University of California / Internet and Information Technology"
              }
            />
          </Grid>
        ))}

        {/* skills */}
        <Grid item sx={{ marginTop: 2 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: "bold", color: "#3F3F46" }}
          >
            Personality
          </Typography>
        </Grid>
        <Grid item sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#3F3F46",
              whiteSpace: "pre-wrap",
            }}
          >
            Adept in managing stakeholders,operating under budget constraints &
            navigating complex regulatory environments Strong experience as an
            advisor to Founders, Directors on product strategy and user journey
            across all features. Strong experience directly to consumers,
            understand pain points,what they are looking for and iterate
            engineering. Excellent ability to foresee emerging technology trends
            in related domain and quickly leverage those trends to build
            world-class products with future oriented architecture, strategic
            partnership, operations efficiency & cost Good experience in
            maintaining effective communication between all parties while
            considering long term and short term objectives and propose changes
            which can enhance operational efficiency and effectiveness Strong
            experience in evaluating suitable vendors and performing due
            diligence for buying licenses of applications that can’t be
            developed internally. Create top-performing team culture from
            scratch with high levels of team engagement and growth mindset
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
