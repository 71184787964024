import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";

// icons and logos
import cv from "../../assets/icons/cv.svg";
import search from "../../assets/icons/search.svg";
import check from "../../assets/icons/check.svg";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HowTo() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const textSmall = useMediaQuery(theme.breakpoints.down("md"));
  const textMedium = useMediaQuery(theme.breakpoints.down("lg"));
  const paddingDimensions = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{ backgroundColor: "#E5E5E5", py: 2, px: paddingDimensions ? 4 : 20 }}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Typography
            sx={{
              fontSize: textSmall ? 28 : textMedium ? 48 : 64,
              fontWeight: 800,
              color: "#3F3F46",
            }}
          >
            How it works
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="flex-start" sx={{ my: 5 }}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                badgeContent={
                  <Avatar sx={{ bgcolor: "#1D4ED8", width: 22, height: 22 }}>
                    <Typography sx={{ fontSize: 14 }}>1</Typography>
                  </Avatar>
                }
              >
                <Avatar sx={{ bgcolor: "#D4D4D4", p: 5 }}>
                  <img alt={"cv"} src={cv} />
                </Avatar>
              </Badge>
            </Grid>
            <Grid item xs={7}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: textSmall ? 18 : textMedium ? 20 : 24,
                      fontWeight: 800,
                      color: "#3F3F46",
                    }}
                  >
                    Register your CV on iSAP Jobs
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#3F3F46",
                    }}
                  >
                    We have a special functionality for registration and CV
                    creation
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ my: isMobile ? 4 : 0 }}
        >
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                badgeContent={
                  <Avatar sx={{ bgcolor: "#1D4ED8", width: 22, height: 22 }}>
                    <Typography sx={{ fontSize: 14 }}>2</Typography>
                  </Avatar>
                }
              >
                <Avatar sx={{ bgcolor: "#D4D4D4", p: 5 }}>
                  <img alt={"search"} src={search} />
                </Avatar>
              </Badge>
            </Grid>
            <Grid item xs={7}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: textSmall ? 18 : textMedium ? 20 : 24,
                      fontWeight: 800,
                      color: "#3F3F46",
                    }}
                  >
                    AI will search for the best projects for you
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}
                  >
                    We will find the best offers that will suit your skills
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                badgeContent={
                  <Avatar sx={{ bgcolor: "#1D4ED8", width: 22, height: 22 }}>
                    <Typography sx={{ fontSize: 14 }}>3</Typography>
                  </Avatar>
                }
              >
                <Avatar sx={{ bgcolor: "#D4D4D4", p: 5 }}>
                  <img alt={"check"} src={check} />
                </Avatar>
              </Badge>
            </Grid>
            <Grid item xs={7}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: textSmall ? 18 : textMedium ? 20 : 24,
                      fontWeight: 800,
                      color: "#3F3F46",
                    }}
                  >
                    Get a dream job
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}
                  >
                    Choose a job that suits you
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
