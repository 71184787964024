import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// custom components
import JobQuantity from "./job-quantity";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function JobsByShort() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Grid container direction="column" sx={{ px: 5, py: 3 }}>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #3F3F46" }}
          >
            <Button
              variant="transparent"
              color="secondary"
              sx={{
                fontWeight: 800,
                color: "#3F3F46",
              }}
            >
              Jobs by role
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #A3A3A3" }}
          >
            <Button variant="transparent" color="secondary">
              Jobs by skill
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #A3A3A3" }}
          >
            <Button variant="transparent" color="secondary">
              Jobs by sector
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #A3A3A3" }}
          >
            <Button variant="transparent" color="secondary">
              Jobs by location
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #A3A3A3" }}
          >
            <Button variant="transparent" color="secondary">
              Jobs by company
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            sx={{ borderRadius: 0, borderBottom: "2px solid #A3A3A3" }}
          >
            <Button variant="transparent" color="secondary">
              Popular searches
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ paddingTop: isTablet ? 4 : 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {[
                { name: "Analyst", no: "1,653" },
                { name: "Android developer", no: "75" },
                { name: "Business analyst", no: "552" },
                { name: "Business intelligence", no: "1,522" },
                { name: "Data analyst", no: "491" },
                { name: "Entry level IT", no: "37" },
                { name: "Graduate IT", no: "34" },
                { name: "Head of IT", no: "54" },
                { name: "iOS developer", no: "64" },
              ].map((job, index) => (
                <Grid item key={index}>
                  <JobQuantity job={job.name} quantity={job.no} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {[
                { name: "IT director", no: "25" },
                { name: "IT helpdesk", no: "386" },
                { name: "IT manager", no: "499" },
                { name: "IT project manager", no: "525" },
                { name: "IT sales", no: "414" },
                { name: "IT support", no: "719" },
                { name: "IT technician", no: "689" },
                { name: "IT trainer", no: "5" },
                { name: "Mobile developer", no: "111" },
              ].map((job, index) => (
                <Grid item key={index}>
                  <JobQuantity job={job.name} quantity={job.no} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {[
                { name: "Network engineer", no: "167" },
                { name: "PHP developer", no: "99" },
                { name: "Programme manager", no: "241" },
                { name: "Project management", no: "2,605" },
                { name: "Project support", no: "2,075" },
                { name: "SAP consultant", no: "36" },
                { name: "Security manager", no: "87" },
                { name: "Software developer", no: "4,774" },
                { name: "Software engineer", no: "4,782" },
              ].map((job, index) => (
                <Grid item key={index}>
                  <JobQuantity job={job.name} quantity={job.no} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {[
                { name: "Software testing", no: "1,468" },
                { name: "Test analyst", no: "108" },
                { name: "Test manager", no: "44" },
                { name: "Tester", no: "2,424" },
                { name: "Testing", no: "2,447" },
                { name: "Web developer", no: "1,532" },
              ].map((job, index) => (
                <Grid item key={index}>
                  <JobQuantity job={job.name} quantity={job.no} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
