import * as React from "react";

// components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// icons and logos
import forname from "../../../assets/icons/for-name.svg";
import fortime from "../../../assets/icons/for-time.svg";
import fordate from "../../../assets/icons/for-posted.svg";
import forplace from "../../../assets/icons/for-place.svg";

// custom components
import JobTag from "../../search/job-tag";

export default function BookedCard(props) {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#E5E5E5", p: 2, borderRadius: 0, my: 2 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item>
              <img
                src={props.info.image}
                alt="name_photo"
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    sx={{ color: "#3F3F46", fontSize: 18, fontWeight: "bold" }}
                  >
                    Damiano Lindelof @ Apple
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={3}>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={forname}
                            alt="for-name"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"Software Engineer"}
                        makeBold={true}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={fordate}
                            alt="for-time"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"2022-03-21"}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={fortime}
                            alt="for-time"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"15:30"}
                      />
                    </Grid>
                    <Grid item>
                      <JobTag
                        icon={
                          <img
                            src={forplace}
                            alt="for-place"
                            style={{ marginRight: 4 }}
                          />
                        }
                        value={"Google Meet"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: 1 }}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Button
                        variant="primary"
                        color="tertiary"
                        sx={{ fontSize: 12 }}
                      >
                        Read CV
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="primary"
                        color="tertiary"
                        sx={{ fontSize: 12 }}
                      >
                        View Requirements
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="stretch" spacing={1}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Button
                  href={`/admin-dashboard/search-cv/${props.info.candidate_id}`}
                  variant="tertiary"
                  sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
                >
                  Message Candidate
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="secondary"
                  color="reverse"
                  sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
                >
                  Message Client
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="secondary"
                sx={{ width: "100%", fontWeight: 700, fontSize: 14 }}
              >
                Edit Booking
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
