import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export default function Personality() {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              required
              fullWidth
              id="characteristic-personality"
              label="Characteristic"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              required
              fullWidth
              id="hobby-personality"
              label="Hobby"
              variant="standard"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              required
              fullWidth
              id="habbits-personality"
              label="Bad habits"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              required
              fullWidth
              id="language-personality"
              label="Language"
              variant="standard"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
