import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function Education() {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <TextField
          required
          fullWidth
          id="name-education"
          label="Name of the educational institution"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <TextField
          required
          fullWidth
          id="faculty-education"
          label="Faculty"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            mask="____-__-__"
            label="Start"
            value={startDate}
            inputFormat="yyyy-MM-dd"
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField required fullWidth variant="standard" {...params} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            mask="____-__-__"
            label="Finished"
            value={endDate}
            inputFormat="yyyy-MM-dd"
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField required fullWidth variant="standard" {...params} />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
