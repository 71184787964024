import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function JobQuantity(props) {
  return (
    <Grid container direction="row">
      <Grid item>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#3F3F46" }}>
          {props.job}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#A3A3A3" }}>
          &nbsp;({props.quantity})
        </Typography>
      </Grid>
    </Grid>
  );
}
