import * as React from "react";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function ProjectDetailedFilter() {
  return (
    <Box>
      <Grid container direction="row">
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#1D4ED8",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#1D4ED8",
              },
              borderRadius: 0,
            }}
          >
            Candidates (134)
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#A3A3A3",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#A3A3A3",
              },
              borderRadius: 0,
            }}
          >
            Viewed CV (43)
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#A3A3A3",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#A3A3A3",
              },
              borderRadius: 0,
            }}
          >
            Selected (10)
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#A3A3A3",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#A3A3A3",
              },
              borderRadius: 0,
            }}
          >
            First Interview (3)
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#A3A3A3",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#A3A3A3",
              },
              borderRadius: 0,
            }}
          >
            Technical Interview (0)
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              py: 1.5,
              px: 5,
              backgroundColor: "#A3A3A3",
              color: "white",
              boxShadow: "0px 0px 30px rgba(39, 39, 42, 0.1)",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#A3A3A3",
              },
              borderRadius: 0,
            }}
          >
            Contract (0)
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
