import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function Employment() {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <TextField
              required
              fullWidth
              id="name-organization"
              label="Name of the organization"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <TextField
              required
              fullWidth
              id="position-organization"
              label="Position"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                mask="____-__-__"
                label="Start"
                value={startDate}
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                mask="____-__-__"
                label="Finished"
                value={endDate}
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          required
          fullWidth
          id="description-organization"
          label="Description"
          variant="standard"
        />
      </Grid>
    </Grid>
  );
}
