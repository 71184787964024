import * as React from "react";
import axios from "axios";

// components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// custom components
import JobAd from "../search/job-ad";
import SearchPrompt from "./search";
import FilterSearch from "./filter";
import PaginationC from "./pagination";

// miscellaneous
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// constants
import { API_URL } from "../../constants";

export default function SearchMain() {
  // create constant to look for changes in the device display size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // rest api variables
  const [allJobs, setAllJobs] = React.useState([]);

  React.useEffect(() => {
    // get all jobs
    axios.get(`${API_URL}/getAllJobs`).then((res) => {
      setAllJobs(res.data);
    });
  }, []);

  return (
    <Box>
      <Grid
        container
        direction="column"
        sx={{ py: 15, px: isMobile ? 4 : 20 }}
        spacing={2}
      >
        <Grid item>
          <Typography sx={{ fontSize: 18, fontWeight: 800, color: "#3F3F46" }}>
            23,447 IT Jobs
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <SearchPrompt />
                </Grid>
                <Grid item>
                  <FilterSearch />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    {allJobs.map((job, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        key={index}
                      >
                        <JobAd
                          jobname={job.jobname}
                          description={job.description}
                          time={job.time}
                          location={job.location}
                          admin={false}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item>
                  <PaginationC />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
