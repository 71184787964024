import * as React from "react";

// components
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Grid from "@mui/material/Grid";

export default function PaginationC(props) {
  return (
    <Grid container direction="row" justifyContent="center" sx={{ my: 4 }}>
      <Grid item>
        <Pagination
          count={props.count}
          page={props.page}
          onChange={props.handleChange}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                "&.Mui-selected": {
                  height: 40,
                  width: 40,
                  borderRadius: 0,
                  backgroundColor: "#3F3F46",
                  borderColor: "#3F3F46",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#3F3F46",
                    opacity: 0.7,
                  },
                },
                height: 40,
                width: 40,
                borderRadius: 0,
                backgroundColor: "white",
                borderColor: "white",
                color: "#3F3F46",
                ":hover": {
                  backgroundColor: "#E5E5E5",
                  borderColor: "#E5E5E5",
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
