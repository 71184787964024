import * as React from "react";

// components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function AdminInterviewFilter() {
  const [company, setCompany] = React.useState("");
  const [category, setCategory] = React.useState("");

  const handleCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Grid container direction="row" alignItems="flex-end" spacing={4}>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Company
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ my: 1, backgroundColor: "#E5E5E5" }}
            >
              <Select
                labelId="continent-label"
                id="continent"
                value={company}
                onChange={handleCompany}
                disableUnderline
                sx={{
                  color: "#3F3F46",
                  fontSize: 14,
                  fontWeight: "bold",
                  px: 2,
                  py: 1,
                }}
              >
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Asia"}
                >
                  Amazon
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Africa"}
                >
                  Apple
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Europe"}
                >
                  Google
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"North America"}
                >
                  Meta
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"South America"}
                >
                  Nokia
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Australia"}
                >
                  Samsung
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Categories
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ my: 1, backgroundColor: "#E5E5E5" }}
            >
              <Select
                labelId="country-label"
                id="country"
                value={category}
                onChange={handleCategory}
                disableUnderline
                sx={{
                  color: "#3F3F46",
                  fontSize: 14,
                  fontWeight: "bold",
                  px: 2,
                  py: 1,
                }}
              >
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Germany"}
                >
                  Category #1
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"France"}
                >
                  Category #2
                </MenuItem>
                <MenuItem
                  sx={{ color: "#3F3F46", fontSize: 14, fontWeight: "bold" }}
                  value={"Sweden"}
                >
                  Category #3
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{
                color: "#3F3F46",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Hourly Rate
            </Typography>
          </Grid>
          <Grid item sx={{ my: 1 }}>
            <Grid
              container
              directon="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  id="start"
                  fullWidth
                  placeholder="Start"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{ backgroundColor: "#E5E5E5", px: 2, py: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="to"
                  fullWidth
                  placeholder="To"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{ backgroundColor: "#E5E5E5", px: 2, py: 1 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="primary"
          sx={{ width: "100%", my: 1, py: 1.3, fontWeight: 700 }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
}
