import * as React from "react";
// components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// icons and logo
import search from "../../assets/icons/ab-search-white.svg";
import projects from "../../assets/icons/ab-projects-white.svg";
import check from "../../assets/icons/check-circle.svg";
import notifications from "../../assets/icons/booked-interview.svg";
import joboffers from "../../assets/icons/job-offers.svg";
import checkDark from "../../assets/icons/check-circle-dark.svg";
import notificationsDark from "../../assets/icons/booked-interview-dark.svg";
import joboffersDark from "../../assets/icons/job-offers-dark.svg";
import profilepic from "../../assets/logos/temp.svg";
import projectpic from "../../assets/logos/temp-apple.svg";

// custom components
import AdminCandidateCard from "./components/candidate-welcome-card";
import AdminProjectsCard from "./components/project-welcine-card";

export default function AdminHome(props) {
  const [applied, setApplied] = React.useState(true);
  const [interviews, setInterviews] = React.useState(false);
  const [offers, setOffers] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#F5F5F5", px: 8 }}>
      <Grid container direction="column">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{ paddingTop: 5 }}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 64,
                      fontWeight: "bold",
                      color: "#3F3F46",
                    }}
                  >
                    Welcome
                  </Typography>
                </Grid>
                <Grid item sx={{ marginTop: 1 }}>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: 500, color: "#3F3F46" }}
                  >
                    to iSAP Jobs
                  </Typography>
                </Grid>
                <Grid item sx={{ my: 4 }}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#3F3F46",
                      textAlign: "center",
                    }}
                  >
                    The world's leading platfrom for outsourcing and hiring IT
                    specialists!
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={4}
                  >
                    <Grid item xs={6}>
                      <Button
                        href="/admin-dashboard/search-cv"
                        variant="secondary"
                        sx={{ width: "100%", py: 1.25, fontWeight: 700 }}
                        startIcon={<img src={search} alt="search" />}
                      >
                        Search CV
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        href="/admin-dashboard/projects"
                        variant="primary"
                        sx={{ width: "100%", py: 1.25, fontWeight: 700 }}
                        startIcon={<img src={projects} alt="projects" />}
                      >
                        Create project
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ my: 4 }}>
          <Divider />
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ paddingTop: 2 }}
          >
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  setApplied(true);
                  setInterviews(false);
                  setOffers(false);
                }}
                variant="primary"
                color={!applied ? "secondary" : "primary"}
                sx={{
                  py: 1.5,
                  width: "100%",
                  fontWeight: "bold",
                  ":hover": {
                    opacity: props.signUp ? 0.7 : 1,
                  },
                }}
                startIcon={
                  <img
                    src={!applied ? checkDark : check}
                    alt="check"
                    style={{ marginRight: 8 }}
                  />
                }
              >
                Jobs Applied
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  setApplied(false);
                  setInterviews(true);
                  setOffers(false);
                }}
                variant="primary"
                color={!interviews ? "secondary" : "primary"}
                sx={{
                  py: 1.5,
                  width: "100%",
                  fontWeight: "bold",
                  ":hover": {
                    opacity: !props.signUp ? 0.7 : 1,
                  },
                }}
                startIcon={
                  <img
                    src={!interviews ? notificationsDark : notifications}
                    alt="booked"
                    style={{ marginRight: 8 }}
                  />
                }
              >
                Booked Interviews
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  setApplied(false);
                  setInterviews(false);
                  setOffers(true);
                }}
                variant="primary"
                color={!offers ? "secondary" : "primary"}
                sx={{
                  py: 1.5,
                  width: "100%",
                  fontWeight: "bold",
                  ":hover": {
                    opacity: !props.signUp ? 0.7 : 1,
                  },
                }}
                startIcon={
                  <img
                    src={!offers ? joboffersDark : joboffers}
                    alt="offers"
                    style={{ marginRight: 8 }}
                  />
                }
              >
                Job Offers
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ my: 4 }}>
          <Box sx={{ bgcolor: "#3F3F46", py: 1, px: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  sx={{ fontWeight: 700, fontSize: 18, color: "white" }}
                >
                  New Candidates
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontWeight: 700, fontSize: 18, color: "white" }}
                >
                  Result: 4
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          <Grid container direction="row" spacing={4}>
            {Array.from(Array(4)).map((card, index) => (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                <AdminCandidateCard
                  image={profilepic}
                  fullName={"John Doe"}
                  role={"Software Developer & IT Consultant"}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item sx={{ my: 4 }}>
          <Box sx={{ bgcolor: "#3F3F46", py: 1, px: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  sx={{ fontWeight: 700, fontSize: 18, color: "white" }}
                >
                  New Projects
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontWeight: 700, fontSize: 18, color: "white" }}
                >
                  Result: 4
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item sx={{ marginBottom: 5 }}>
          <Grid container direction="row" spacing={4}>
            {Array.from(Array(4)).map((card, index) => (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                <AdminProjectsCard
                  image={projectpic}
                  title={"Mobile Native Engineer"}
                  description={
                    "As a member of the development team, the Hybrid Mobile Developer will be responsible for developing web-based mobile applications, particularly using JavaScript and Apache"
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
