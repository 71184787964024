import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// components
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";

// icons and logos
import CloseIcon from "@mui/icons-material/Close";

// custom components
import SocialMediaAuth from "../../auth/social-media-signIn";

// constants
import { API_URL } from "../../../constants";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BusinessSignIn(props) {
  let navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const signInManually = () => {
    // axios call & body
    const body = {
      email: email,
      pass: password,
    };

    console.log(body);

    axios.post(`${API_URL}/businesssignin`, body).then((res) => {
      console.log(res.data);

      if (res.data.length !== 0) {
        setSuccessSnackbar(true);
        // store the user in localStorage
        localStorage.setItem("businessUser", JSON.stringify(res.data));
        navigate("/business-dashboard");
      } else {
        setErrorSnackbar(true);
      }
    });
  };

  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbar(false);
  };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      <Grid item>
        <TextField
          required
          fullWidth
          id="email-signin"
          label="Email"
          variant="standard"
          onChange={(event) => setEmail(event.target.value)}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          fullWidth
          id="password-required"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="standard"
          onChange={(event) => setPassword(event.target.value)}
        />
      </Grid>

      <Grid item>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Button
              onClick={signInManually}
              variant="primary"
              disableElevation
              sx={{
                my: 3,
                px: 5,
                fontSize: 16,
              }}
            >
              Sign In
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Divider spacing={1} sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "normal", fontSize: 15, color: "gray" }}>
          OR
        </Typography>
      </Divider>

      <SocialMediaAuth business={true} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSuccessClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully signed in!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleErrorClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error signing in
        </Alert>
      </Snackbar>
    </Grid>
  );
}
